import React from 'react';
import { Grid } from '@mui/material';
import { AddressRequest } from '../../store/actions/ActionTypes';

interface Props {
	address: AddressRequest;
	withoutAlias?: boolean;
}

const AddressCell: React.FC<Props> = (props) => {
	return (
		<Grid container direction='column' style={{ margin: '0 auto', lineHeight: 1.5 }}>
			{!props.withoutAlias && <div data-type='uppercase'>{props.address.alias}</div>}
			<div data-type='uppercase'>
				<strong>{props.address.business_name}</strong>
			</div>
			<div data-type='uppercase'>{props.address.contact?.name}</div>
		</Grid>
	);
};

export default AddressCell;
