import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const radiusCard = 20;
export const maxWidthContainerCard = 960;
export const maxWidthContainer = 1200;

//TODO revisar si el padding tambien se puede factorizar
// TODO remove the root class

const cardStyles = makeStyles((theme: Theme) => ({
	// Obscuro
	card: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: radiusCard
	},
	hardColor: {
		backgroundColor: theme.palette.background.default
	},
	softColor: {
		backgroundColor: theme.palette.background.paper
	},
	// Clarito
	root: {
		borderRadius: radiusCard,
		backgroundColor: theme.palette.background.paper
	},
	bottomBorderOnly: {
		borderRadius: '0 0 20px 20px'
	}
}));

export const useCardStyles = () => {
	const cardClasses = cardStyles();
	return { cardClasses };
};

//=============================================================

const btnAddAutocompleteStyles = makeStyles((theme: Theme) => ({
	btnAdd: {
		//marginTop: 20,
		color: theme.palette.primary.main,
		justifyContent: 'flex-start',
		height: 45,
		backgroundColor: 'inherit'
	},
	iconAdd: {
		color: theme.palette.primary.main,
		backgroundColor: 'rgba(255, 255, 255, 0.08)',
		padding: 3
	},
	withoutStyles: {
		border: 0,
		background: 'transparent',
		color: '#ABABAC',
		'&:hover': {
			color: 'white',
			cursor: 'pointer'
		},
		'&:disabled': {
			'&:hover': {
				color: '#ABABAC',
				cursor: 'auto'
			}
		}
	}
}));
export const useAddBtnAutocompleteStyles = () => {
	const btnAutocompleteClasses = btnAddAutocompleteStyles();
	return { btnAutocompleteClasses };
};

//=============================================================

const tabsStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		borderRadius: '20px 20px 0 0',
		'&>*': {
			flex: 1,
			maxWidth: 'unset',
			fontWeight: 700,
			justifySelf: 'flex-start'
		}
	},
	option: {
		width: 150,
		borderBottom: 'solid 0.9px rgb(81, 81, 81)',
		paddingLeft: 0,
		paddingRight: 0
		// '& > span:nth-child(1)': {
		// 	borderRight: '1px solid #505050'
		// }
	},
	label: {
		fontSize: 25
	},
	optionSelected: {
		borderBottom: 'solid 4px ' + theme.palette.primary.main,
		backgroundColor: '#4d4d4d',
		borderTopLeftRadius: '20px',
		borderTopRightRadius: '20px'
	}
}));

export const useTabsStyles = () => {
	const tabsClasses = tabsStyles();
	return { tabsClasses };
};

//=============================================================

const tableStyles = makeStyles((theme: Theme) => ({
	root: {
		'& p[data-type="uppercase"]': {
			textTransform: 'uppercase'
		},
		'& div[data-type="uppercase"]': {
			textTransform: 'uppercase'
		},
		tableMUIContainer: {
			marginTop: theme.spacing(2),
			border: '1px solid rgb(88, 87, 87) !important',
			boxShadow: 'none !important',
			borderRadius: '8px',
			color: 'white !important',
			'& *': {
				color: 'white !important'
			},
			'& th': {
				borderBottom: '1px solid rgba(81, 81, 81, 1)'
			},
			'& td': {
				borderBottom: '1px solid rgba(81, 81, 81, 1)'
			}
		},
		tableMUIEmpty: {
			height: '124px',
			color: 'white !important'
		},
		tableMUIPagination: {
			display: 'inline-block',
			margin: '0',
			color: 'white !important',
			'& td': {
				borderBottom: '1px solid rgba(81, 81, 81, 1)'
			}
		},
		tableMUIHeaderColumn: {
			borderLeft: '2px solid white',
			paddingLeft: '12px',
			color: 'white !important'
		}
	},
	'& div[data-type="uppercase"] input': {
		textTransform: 'uppercase'
	},
	'& input[data-type="capitalize"]': {
		textTransform: 'capitalize'
	},
	'& p[data-type="capitalize"]': {
		textTransform: 'capitalize'
	},
	'& div[data-type="capitalize"]': {
		textTransform: 'capitalize'
	},
	input: {
		'& input': {
			textTransform: 'uppercase'
		}
	},
	tableMUIContainer: {
		marginTop: theme.spacing(2),
		border: '1px solid rgb(88, 87, 87) !important',
		boxShadow: 'none !important',
		borderRadius: '8px',
		background: `${theme.palette.background.paper} !important`,
		color: 'white !important',
		'& *': {
			color: 'white !important'
		},
		'& th': {
			borderBottom: '1px solid rgba(81, 81, 81, 1)',
			paddingRight: '0 !important'
		},
		'& td': {
			borderBottom: '1px solid rgba(81, 81, 81, 1)',
			paddingRight: '0 !important'
		}
	},
	tableMUIEmpty: {
		height: '124px'
	},
	tableMUIPagination: {
		display: 'inline-block',
		margin: '0',
		color: 'white !important',
		'& td': {
			borderBottom: '1px solid rgba(81, 81, 81, 1)'
		}
	},
	tableMUIHeaderColumn: {
		borderLeft: '2px solid white',
		paddingLeft: '12px'
	}
}));

export const useTableStyles = () => {
	const tableClasses = tableStyles();
	return { tableClasses };
};

//=============================================================

const modalStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		'& p': {
			margin: 0
		}
	}
}));

export const useModalStyles = () => {
	const modalClasses = modalStyles();
	return { modalClasses };
};
