import { Fragment, FC, Dispatch, SetStateAction, useMemo } from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { PermissionsKey } from '~context/AuthContext';
import usePermissions from '~hooks/usePermissions';
import { TextOne } from '~components/Headings';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		borderRadius: '20px 20px 0 0',
		'&>*': {
			flex: 1,
			maxWidth: 'unset !important',
			fontWeight: 700,
			justifySelf: 'flex-start'
		}
		//backgroundColor: `${theme.palette.background.default} !important`
	},
	menuOption: {
		width: 150,
		borderBottom: 'solid 0.9px rgb(81, 81, 81) !important',
		color: `rgba(255, 255, 255, 0.7) !important`
	},
	label: {
		fontSize: 25
	},
	menuOptionSelected: {
		borderBottom: `solid 2px ${theme.palette.primary.main} !important`
	},
	tabBorder: {
		boxSizing: 'border-box',
		borderBottom: 'solid 0.9px rgb(81, 81, 81)',
		paddingLeft: 0,
		paddingRight: 0,
		'& > span:nth-child(1)': {
			marginRight: '-5px',
			borderRight: '1px solid #505050',
			width: '100%'
		}
	}
}));

type BookletOption = { path: string; name: string; permission: PermissionsKey };

interface Props {
	currentTab: 'cotizar' | 'enviar' | 'recolectar' | 'carga-masiva' | null;
	setReset?: Dispatch<SetStateAction<boolean>>;
}

// FIXME dont use the hoxfix
// TODO move this logic to the Routes.tsx file
// const booklets: BookletOption[] = [
// 	{ path: '/cotizar', name: 'Cotizar', permission: 'canRead.rate' },
// 	{ path: '/enviar', name: 'Enviar', permission: 'canRead.label' },
// 	// { path: '/carga-masiva', name: 'Carga Masiva', permission: 'canRead.label' },
// 	{ path: '/recolectar', name: 'Recolectar', permission: 'canRead.pickup' },
// ];

const MenuTabs: FC<Props> = (props) => {
	const classes = useStyles();

	const { isRootOrAgent, can } = usePermissions();
	// const [reset, setReset] = useState(false);
	const booklets: BookletOption[] = useMemo(
		() =>
			isRootOrAgent
				? [
						{ path: '/cotizar', name: 'Cotizar', permission: 'canCreateUpdateDelete.rate' },
						{ path: '/enviar', name: 'Enviar', permission: 'canCreateUpdateDelete.label' },
						// { path: '/carga-masiva', name: 'Carga Masiva', permission: 'canCreateUpdateDelete.label' },
						{ path: '/recolectar', name: 'Recolectar', permission: 'canCreateUpdateDelete.pickup' }
				  ]
				: [
						{ path: '/cotizar', name: 'Cotizar', permission: 'canCreateUpdateDelete.rate' },
						{ path: '/enviar', name: 'Enviar', permission: 'canCreateUpdateDelete.label' },
						{ path: '/recolectar', name: 'Recolectar', permission: 'canCreateUpdateDelete.pickup' }
				  ],
		[isRootOrAgent]
	);

	const history = useHistory();
	const location = useLocation();

	const enabledTabs = useMemo(
		() => booklets.filter((booklet) => can(booklet.permission)),
		[can, booklets]
	);
	const tabs = useMemo(() => enabledTabs.map((booklet) => booklet.name), [enabledTabs]);

	const currentTab = useMemo(() => {
		return enabledTabs.findIndex((b) => b.path === location.pathname);
	}, [location, enabledTabs]);

	// console.log(currentTab);

	return (
		<Fragment>
			<BottomNavigation
				value={props.currentTab}
				onChange={(_, newValue) => {
					const route = enabledTabs[newValue].path;
					history.replace(route as string);
				}}
				showLabels
				className={classes.root}
			>
				{tabs.map((tabName, index) => (
					<BottomNavigationAction
						key={tabName}
						className={clsx([
							classes.menuOption,
							currentTab === index && classes.menuOptionSelected,
							index !== tabs.length - 1 && currentTab !== index && classes.tabBorder
						])}
						label={<TextOne style={{ fontWeight: 400, padding: 0 }} text={tabName} />}
					/>
				))}
			</BottomNavigation>

			{/* Control to select customers if he/she is an agent */}
			{/* {isRootOrAgent && currentTab > -1 && <CustomerSelector setReset={props.setReset} />} */}
		</Fragment>
	);
};

export default MenuTabs;
