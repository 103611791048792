import { Badge, Button, Divider, IconButton, Menu, MenuItem, Grid } from '@mui/material';
import { useState } from 'react';
import { Bell as NotificationIcon } from 'react-feather';
import { shallow } from 'zustand/shallow';
import useNotificationStore from '~store/newstore/useNotificationStore';

export default function NotificationTopNav() {
	const [notifications, clearNotifications] = useNotificationStore(
		(state) => [state.notificationsQueue, state.clearNotificationsQueue],
		shallow
	);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	return (
		<Grid container alignItems='center'>
			<IconButton color='inherit' onClick={handleClick}>
				{notifications.length ? (
					<Badge badgeContent={notifications.length} color='error' overlap='rectangular'>
						<NotificationIcon />
					</Badge>
				) : (
					<NotificationIcon />
				)}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{ top: '42px' }}
				MenuListProps={{style: {backgroundColor: '#212121', color: '#f2f2f2'}}}
			>
				{notifications.map((notification, i) => {
					return (
						<MenuItem
							key={notification.date.toString() + i}
							onClick={() => {
								notification.callBack?.();
								handleClose();
							}}
						>
							{notification.message}
							<br />
							{notification.date.toLocaleString('es-ES')}
						</MenuItem>
					);
				})}
				{notifications.length > 0 ? (
					<div>
						<Divider />
						<MenuItem
							onClick={() => {
								clearNotifications();
								handleClose();
							}}
						>
							<Button fullWidth>Limpiar notificaciones</Button>
						</MenuItem>
					</div>
				) : (
					<MenuItem onClick={handleClose}>No hay notificaciones</MenuItem>
				)}
			</Menu>
		</Grid>
	);
}
