import React, { useContext, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
	AppBar,
	Avatar,
	Toolbar,
	IconButton,
	MenuItem,
	Menu,
	Tooltip,
	Divider
} from '@mui/material';
import { Menu as MenuIcon, MoreVertical as MoreIcon } from 'react-feather';

import NotificationTopNav from '../TopNavComponents/NotificationTopNav';
import CustomerSelector from '~components/Inputs/CustomerSelector';

import { useStyles } from './styles';
import AuthContext from '~context/AuthContext';
import DrawContext from '~context/DrawContext';
import { resetFlow } from '~store/actions';
import usePermissions from '~hooks/usePermissions';
import instanceAPI, { instanceRate, instancePayment } from '~util/axios';
import logo from '~assets/logo.svg';
import useNotificationStore from '~store/newstore/useNotificationStore';
import { shallow } from 'zustand/shallow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { TextOne } from '~components/Headings';
import { formatPrice } from '~util/formatPrice';
import { useSelector } from '~store/store';
import useBalanceStore from '~store/useBalanceStore';
import { getUserBalance } from '~store/actions/DataActions';

interface Props {
	currentTheme: boolean;
	toggleTheme: () => void;
}

const TopNav: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const { setAuth, currentUser, clearUser } = useContext(AuthContext);
	const { toggleLeftDrawer, showBarLogo, availableBalance, setAvailableBalance } =
		useContext(DrawContext);
	const { isCustomer, isRootOrAgent, isSeller } = usePermissions();
	const { simulatedCustomer, simulatedUser } = useSelector((state) => state.simulatedUser);
	const dispatch = useDispatch();

	let matchClients = useRouteMatch('/clientes');
	let matchClient = useRouteMatch('/cliente');
	let matchAgent = useRouteMatch('/agente');
	let matchAgents = useRouteMatch('/agentes');
	let matchOrder = useRouteMatch('/orden');
	let matchSummary = useRouteMatch('/resultados');
	let matchRate = useRouteMatch('/tarifas');
	let matchFiscalForm = useRouteMatch('/razon-social');
	let matchFiscalSummary = useRouteMatch('/resumen-razon-social');
	let matchGroupForm = useRouteMatch('/grupo');
	let matchProspects = useRouteMatch('/prospectos');
	let matchProspect = useRouteMatch('/prospecto');
	let matchDispersion = useRouteMatch('/dispersion');

	const matchRoutes = useMemo(
		() =>
			Boolean(
				matchClients ||
					matchAgents ||
					matchOrder ||
					matchSummary ||
					matchAgent ||
					matchClient ||
					matchRate ||
					matchFiscalForm ||
					matchFiscalSummary ||
					matchGroupForm ||
					matchProspects ||
					matchProspect ||
					matchDispersion ||
					history.location.pathname === '/'
			),
		[
			matchClients,
			matchAgents,
			matchOrder,
			matchSummary,
			matchAgent,
			matchClient,
			matchRate,
			matchFiscalForm,
			matchFiscalSummary,
			matchGroupForm,
			matchProspects,
			matchProspect,
			matchDispersion,
			history.location.pathname
		]
	);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
	const { customerHasBalance } = useBalanceStore();

	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const [clearNotifications] = useNotificationStore(
		(state) => [state.clearNotificationsQueue],
		shallow
	);

	useEffect(() => {
		if (customerHasBalance(isRootOrAgent ? simulatedCustomer : currentUser?.customer)) {
			getUserBalance(isRootOrAgent, setAvailableBalance, simulatedCustomer?.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableBalance, currentUser, simulatedCustomer, isRootOrAgent]);

	const menuTop = useCallback(() => {
		return (
			<div>
				<MenuItem disabled style={{ opacity: 'unset' }}>
					{currentUser.name}
				</MenuItem>
				{/* {isCustomer && (
					<MenuItem
						onClick={() => {
							history.push(`/cliente/datos/${currentUser?.customer?.id}`);
							handleMenuClose();
						}}
					>
						Empresa
					</MenuItem>
				)} */}
				{!isRootOrAgent && isCustomer && (
					<>
						<Divider />
						<MenuItem
							onClick={() => {
								history.push('/perfil/datos');
								handleMenuClose();
							}}
						>
							Datos generales
						</MenuItem>
						<MenuItem
							onClick={() => {
								history.push('/perfil/tarifas');
								handleMenuClose();
							}}
						>
							Tarifas
						</MenuItem>
						<MenuItem
							onClick={() => {
								history.push('/perfil/documentos');
								handleMenuClose();
							}}
						>
							Documentos
						</MenuItem>
						<MenuItem
							onClick={() => {
								history.push('/perfil/contrato');
								handleMenuClose();
							}}
						>
							Contrato
						</MenuItem>
						<Divider />
					</>
				)}
				<MenuItem
					onClick={() => {
						history.push('/tutoriales');
						handleMenuClose();
					}}
				>
					Tutoriales
				</MenuItem>
				<Divider className={classes.divider} />
				<MenuItem
					onClick={() => {
						delete instanceAPI.defaults.headers.common['Authorization'];
						delete instanceRate.defaults.headers.common['Authorization'];
						delete instancePayment.defaults.headers.common['Authorization'];
						localStorage.removeItem('auth');
						setAuth(false);
						clearNotifications();
						clearUser();
					}}
				>
					Salir
				</MenuItem>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, setAuth, clearUser, isCustomer]);

	const userMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
			style={{ top: '42px' }}
		>
			{menuTop()}
		</Menu>
	);

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			keepMounted
			transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			{menuTop()}
		</Menu>
	);

	// Top Nav
	return (
		<div className={classes.grow}>
			<AppBar position='fixed' color='inherit' className={classes.appBar}>
				<Toolbar>
					<IconButton
						edge='start'
						className={classes.menuButton}
						color='inherit'
						onClick={() => toggleLeftDrawer()}
					>
						<MenuIcon />
					</IconButton>
					<div className={classes.brand} onClick={() => dispatch(resetFlow())}>
						<Link to='/'>
							<img
								style={{ visibility: showBarLogo ? 'initial' : 'hidden' }}
								src={logo}
								alt={logo}
								height='45px'
							/>
						</Link>
					</div>
					<div className={classes.customerSelector}>
						{/* Simulated customer and user for roots or agents */}
						{(isRootOrAgent || isSeller) && <CustomerSelector matchRoutes={matchRoutes} />}
					</div>
					{/* Available balance */}
					{((!isRootOrAgent && customerHasBalance(currentUser?.customer)) ||
						(isRootOrAgent && customerHasBalance(simulatedUser?.customer ?? simulatedCustomer))) &&
						availableBalance !== null && (
							<Tooltip title='Saldo disponible'>
								<div className={classes.balanceData}>
									<FontAwesomeIcon icon={faWallet} />
									<TextOne text={formatPrice(availableBalance)} />
								</div>
							</Tooltip>
						)}
					<div className={classes.sectionDesktop}>
						{/* Notification */}
						<NotificationTopNav />
						<IconButton edge='end' onClick={handleProfileMenuOpen} color='inherit'>
							<Avatar className={classes.avatar}>{currentUser.name.charAt(0)}</Avatar>
						</IconButton>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton onClick={handleMobileMenuOpen} color='inherit'>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{userMenu}
		</div>
	);
};

export default TopNav;
