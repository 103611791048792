import { useState, useCallback } from 'react';
import { createGenerateClassName } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

declare module '@mui/styles' {
	interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
	interface Palette {
		white: Palette['primary'];
	}

	interface PaletteOptions {
		white?: PaletteOptions['primary'];
	}
}

export const useTheme = (initialTheme?: boolean) => {
	// const defaultTheme = createTheme();
	const generateClassName = createGenerateClassName({
		// By enabling this option, if you have non-MUI elements (e.g. `<div />`)
		// using MUI classes (e.g. `.MuiButton`) they will lose styles.
		// Make sure to convert them to use `styled()` or `<Box />` first.
		disableGlobal: true,
		// Class names will receive this seed to avoid name collisions.
		seed: 'mui-jss'
	});
	const [currentTheme, setCurrentTheme] = useState(!!initialTheme);

	// const gap = (spacing: number) => parseInt(defaultTheme.spacing(spacing), 10);

	const themeV4 = createTheme(
		{
			palette: {
				mode: currentTheme ? 'light' : 'dark',
				primary: {
					main: '#4ec0df'
				},
				background: {
					default: '#181818',
					paper: '#212121'
				},
				error: {
					main: '#aa3e3d'
				},
				white: {
					main: '#fafafa'
				}
			},
			typography: {
				h4: {
					fontWeight: 'bold'
				},
				h5: {
					fontWeight: 'bold'
				}
			},
			breakpoints: {
				values: {
					xs: 0,
					sm: 600,
					md: 900,
					lg: 1300,
					xl: 1536
				}
			},
			components: {
				MuiButton: {
					styleOverrides: {
						root: {
							textTransform: 'capitalize',
							borderRadius: 8,
							padding: '5px 15px',
							'&:hover': {
								borderColor: 'inherit'
							}
						},
						outlined: {
							border: '1px solid #fafafa',
							color: '#fafafa',
							'&:hover': {
								borderColor: 'inherit'
							}
						}
					}
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							color: 'inherit'
						}
					}
				},
				MuiFab: {
					styleOverrides: {
						root: {
							textTransform: 'capitalize'
						}
					}
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							backgroundColor: '#212121 !important',
							color: '#f2f2f2',
							backgroundImage: 'none'
						}
					}
				},
				MuiSelect: {
					styleOverrides: {
						root: {
							backgroundColor: '#353535',
							'& ul': {
								backgroundColor: '#353535'
							}
						}
					}
				},
				MuiTextField: {
					defaultProps: {
						variant: 'standard'
					},
					styleOverrides: {
						root: {
							'& input': {
								// paddingBottom: '5px'
							}
						}
					}
				},
				MuiFilledInput: {
					styleOverrides: {
						root: {
							'& input': {
								padding: '27px 12px 8px'
							},
							select: {
								padding: '27px 12px 8px'
							}
						}
					}
				},

				MuiBottomNavigation: {
					styleOverrides: {
						root: {
							backgroundColor: '#181818'
						}
					}
				},
				MuiPaginationItem: {
					styleOverrides: {
						root: {
							textPrimary: '#4ec0df'
						}
					}
				},
				MuiAutocomplete: {
					styleOverrides: {
						root: {
							color: 'white',
							'& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
								paddingTop: '16px !important'
							}
						},
						popper: {
							color: '#f2f2f2'
						},
						listbox: {
							backgroundColor: 'inherit'
						}
					}
				},
				MuiList: {
					styleOverrides: {
						root: {
							backgroundColor: '#212121 !important',
							color: '#f2f2f2'
						}
					}
				},
				MuiMenuList: {
					styleOverrides: {
						root: {
							backgroundColor: '#212121 !important',
							color: '#f2f2f2'
						}
					}
				},
				MuiDialog: {
					styleOverrides: {
						paper: {
							// backgroundColor: '#212121 !important',
							// color: '#f2f2f2',
							backgroundImage: 'none'
						}
					}
				},
				MuiAccordion: {
					styleOverrides: {
						root: {
							// backgroundColor: '#212121 !important',
							// color: '#f2f2f2',
							backgroundImage: 'none'
						}
					}
				}
			},
			shape: {
				borderRadius: 8
			}
			// props: {}
			// gap,
			// spacing: {
			// 	0: gap(0),
			// 	1: gap(1),
			// 	2: gap(2),
			// 	3: gap(3),
			// 	4: gap(4),
			// 	5: gap(5)
			// }
		},
		esES
	);

	// const themeV5 = createTheme(
	// 	{
	// 		palette: {
	// 			mode: currentTheme ? 'light' : 'dark',
	// 			primary: {
	// 				main: '#4ec0df'
	// 			},
	// 			background: {
	// 				default: '#181818',
	// 				paper: '#212121'
	// 			},
	// 			error: {
	// 				main: '#aa3e3d'
	// 			}
	// 		},
	// 		typography: {
	// 			h4: {
	// 				fontWeight: 'bold'
	// 			},
	// 			h5: {
	// 				fontWeight: 'bold'
	// 			}
	// 		},
	// 		breakpoints: {
	// 			values: {
	// 				xs: 0,
	// 				sm: 600,
	// 				md: 900,
	// 				lg: 1300,
	// 				xl: 1536
	// 			}
	// 		},
	// 		overrides: {
	// 			MuiButton: {
	// 				root: {
	// 					textTransform: 'capitalize'
	// 				}
	// 			},
	// 			MuiFab: {
	// 				root: {
	// 					textTransform: 'capitalize'
	// 				}
	// 			},
	// 			MuiSelect: {
	// 				root: {
	// 					backgroundColor: '#353535',
	// 					'& ul': {
	// 						backgroundColor: '#353535'
	// 					}
	// 				}
	// 			}
	// 		},
	// 		shape: {
	// 			borderRadius: 8
	// 		},
	// 		props: {}
	// 	},
	// 	esES
	// );

	const toggleTheme = useCallback(() => {
		setCurrentTheme((prev) => !prev);
	}, []);

	return { themeV4, toggleTheme, currentTheme, generateClassName, theme: themeV4 };
};
