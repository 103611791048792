import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { getLogoByCarrierName } from '../../util/logos';

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		fontSize: 22
	}
}));

interface Props {
	text: string;
	subtitle?: string;
}

const LogoMessage: React.FC<Props> = (props) => {
	const classes = useStyles();
	return (
		<Grid item xs={12} style={{ marginBottom: 30 }}>
			<Grid container justifyContent='center' alignItems='center' style={{ marginBottom: 15 }}>
				<img src={getLogoByCarrierName('encamino-white')} width={200} alt='encamino' />
			</Grid>
			<Typography align='center' variant='h6' component='p' className={classes.title}>
				{props.text}
			</Typography>
			<Typography align='center' variant='body2' component='p'>
				{props.subtitle}
			</Typography>
		</Grid>
	);
};

export default LogoMessage;
