import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField, CircularProgress } from '@mui/material';
import { setGoogleMapsScriptStatus, setMapCoordinates } from '../../store/actions';

import { RootStore } from '../../store/store';
import loadGMaps from '../../js-scripts/loadGMaps';

import googleMapsIcon from '../../assets/googlemapsicon.png';

export type Place = {
	name?: string;
	street_number?: string;
	route?: string;
	sublocality_level_1?: string;
	locality?: string;
	administrative_area_level_1?: string;
	country?: string;
	postal_code?: string;
	coordinates: {
		lat: number;
		lng: number;
	};
	// municipio
	// region?: string;
};

interface Props {
	searchCallback: (place: Place) => void;
	isRating?: boolean;
}

const AutoCompleteMaps: React.FC<Props> = (props) => {
	/**
	 * Google Maps Scripts
	 */
	const dispatch = useDispatch();
	const { isLoadedGoogleMapsScript, map } = useSelector((state: RootStore) => state.app);
	const { isTouchedRate } = useSelector((state: RootStore) => state.rate);

	const searchItemRef = useRef<HTMLInputElement>(null);
	const [place, setPlace] = useState<Place | null>(null);
	const [showError, setShowError] = useState(false);

	// Handle the error
	useEffect(() => {
		if (props.isRating) {
			setShowError(isTouchedRate && !place);
		}
	}, [props.isRating, isTouchedRate, place]);

	// clean coordiates if exists
	useEffect(() => {
		dispatch(setMapCoordinates(null));
	}, [dispatch]);

	// load script
	useEffect(() => {
		if (!isLoadedGoogleMapsScript) {
			loadGMaps(() => dispatch(setGoogleMapsScriptStatus(true)));
		}
	}, [isLoadedGoogleMapsScript, dispatch]);

	// set up stuff
	useEffect(() => {
		if (isLoadedGoogleMapsScript && searchItemRef.current) {
			const autocomplete = new window.google.maps.places.Autocomplete(searchItemRef.current, {
				componentRestrictions: { country: 'mx' }
			});
			// TODO remove things that wont be needed because "municipio" wont be required to autocomplete
			// Avoid paying for data that you don't need by restricting the set of
			// place fields that are returned to just the address components.
			autocomplete.setFields(['address_components', 'geometry']);
			// autocomplete.setFields(['address_components', 'geometry', 'name', 'adr_address']);
			//autocomplete.setFields();

			if (map) {
				autocomplete.bindTo('bounds', map);
			}

			autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				//console.log('place', place);

				if (!place.geometry) {
					// UserForm entered the name of a Place that was not suggested and
					// pressed the Enter key, or the Place Details request failed.
					//window.alert("No details available for input: '" + place.name + "'");
					return;
				}

				// If the place has a geometry, then present it on a map.
				const coordinates = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng()
				};

				dispatch(setMapCoordinates(coordinates));

				const infoForCallBack: Place = { coordinates: coordinates };

				for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
					const addressType = component.types[0];
					switch (addressType) {
						case 'street_number':
							infoForCallBack[addressType] = component['short_name'];
							break;
						case 'route':
							infoForCallBack[addressType] = component['long_name'];
							break;
						case 'sublocality_level_1':
							infoForCallBack[addressType] = component['long_name'];
							break;
						case 'locality':
							infoForCallBack[addressType] = component['long_name'];
							break;
						case 'administrative_area_level_1':
							infoForCallBack[addressType] = component['long_name'];
							break;
						case 'country':
							infoForCallBack[addressType] = component['long_name'];
							break;
						case 'postal_code':
							infoForCallBack[addressType] = component['short_name'];
							break;
						default:
							break;
					}
				}

				// This was used to determinate the municipality
				// const region = place.adr_address!.toString().match(/<span class="region">(.*?)<\/span>/g);
				// if(region && region?.length > 0){
				// 	infoForCallBack['region'] = region[0].replace(/<\/?span( class="region")?>/g, '')
				// }

				//console.log(place.adr_address.toString().match(/<span class=(.*?)>(.*?)<\/span>/g));
				setPlace(infoForCallBack);
				props.searchCallback(infoForCallBack);
			});
		}
	}, [map, props, isLoadedGoogleMapsScript, dispatch]);

	if (!isLoadedGoogleMapsScript) {
		return <CircularProgress />;
	}

	return (
		<TextField
			fullWidth
			placeholder='Buscar en Google Maps'
			helperText={
				props.isRating
					? null
					: 'Si buscará con Google Maps, por favor complete la información faltante (Municipio, Referencias, Tipo Inmueble, etc)'
			}
			label='Ubicación'
			variant='filled'
			error={showError}
			inputRef={searchItemRef}
			InputProps={{
				startAdornment: (
					<InputAdornment position='start' sx={{ marginTop: '12px' }}>
						<img height={20} src={googleMapsIcon} alt=''></img>
					</InputAdornment>
				)
			}}
		/>
	);
};

export default AutoCompleteMaps;
