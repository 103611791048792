import React, { useState } from 'react';
import { Grid, TextField, Typography, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Controller, useForm } from 'react-hook-form';
import LogoMessage from './_LogoMessage';
import axios from '../../util/axios';
import CopyRight from './_CopyRight';
import GradientButton from '../../components/Buttons/GradientButton';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		border: '1px solid #FFFFFF50',
		marginTop: theme.spacing(3),
		padding: theme.spacing(6),
		maxWidth: 700,
		borderRadius: theme.spacing(1)
	},
	linkTerms: {
		margin: '15px auto',
		'&>a': {
			textDecoration: 'none',
			color: theme.palette.primary.main
		}
	},
	continueBtn: {
		width: '100%',
		maxWidth: 400,
		borderRadius: theme.spacing(3)
	}
}));

type Inputs = {
	email: string;
};

const ForgotPasswordPage = () => {
	const classes = useStyles();
	const { handleSubmit, errors, control } = useForm<Inputs>();

	const [emailSent, setEmailSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (data: Inputs) => {
		setIsLoading(true);
		try {
			// const res =
			await axios.post('/api/user/reset', { email: data.email.trim() });
			//console.log('/api/user/reset response', res);
		} catch (error) {
			//console.log('/api/user/reset/', error.response);
		} finally {
			setIsLoading(false);
			setEmailSent(true);
		}
	};

	if (emailSent) {
		return (
			<Grid container justifyContent='center' alignItems='center'>
				{/* The border */}
				<Grid
					item
					xs={12}
					container
					justifyContent='center'
					alignItems='center'
					className={classes.root}
				>
					{/* Logo and message */}
					<LogoMessage text='¡Correo de recuperación enviado!' />
					{/* Text */}
					<Typography align='center' variant='subtitle1' component='p'>
						Si existe una cuenta con el correo electrónico <br />
						que indicaste, recibirás un correo con las <br />
						instrucciones para recuperar tu constraseña.
					</Typography>
				</Grid>
				<CopyRight />
			</Grid>
		);
	}

	return (
		<Grid container justifyContent='center' alignItems='center'>
			{/* The border */}
			<Grid
				item
				xs={12}
				container
				justifyContent='center'
				alignItems='center'
				className={classes.root}
			>
				{/* Logo and message */}
				<LogoMessage text='¿Olvidaste tu contraseña?' />
				{/* The form */}
				<form autoComplete='off'>
					<Grid container justifyContent='center' alignItems='center' spacing={3}>
						<Grid item xs={12} sm={8}>
							<Typography align='center' variant='body2' component='p'>
								Te enviaremos un correo con las instrucciones para recuperar tu contraseña.
							</Typography>
						</Grid>

						<Grid item xs={12} sm={8}>
							<Controller
								name='email'
								rules={{
									required: 'Ingrese su Email',
									pattern: {
										value:
											/^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/i,
										message: 'Ingrese un correo válido'
									}
								}}
								as={
									<TextField
										variant='filled'
										error={Boolean(errors?.email)}
										fullWidth
										label='Correo&nbsp;*'
									/>
								}
								control={control}
								defaultValue=''
								helperText={errors?.email?.message}
							/>
						</Grid>

						<Grid container item xs={12} justifyContent='center' alignItems='center'>
							{isLoading ? (
								<CircularProgress />
							) : (
								<GradientButton
									color='blue'
									type='submit'
									className={classes.continueBtn}
									onClick={handleSubmit(onSubmit)}
								>
									Continuar
								</GradientButton>
							)}
						</Grid>
					</Grid>
				</form>
			</Grid>
			<CopyRight />
		</Grid>
	);
};

export default ForgotPasswordPage;
