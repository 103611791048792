import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useTrackingStyles = makeStyles((theme: Theme) => ({
	root: {
		margin: `${theme.spacing(8)} 0`,
		[theme.breakpoints.down(750)]: {
			margin: `${theme.spacing(1)} 0`
		}
	},
	container: {
		border: '1px solid #FFFFFF50',
		padding: theme.spacing(4),
		maxWidth: 600,
		borderRadius: theme.spacing(1),
		[theme.breakpoints.down(750)]: {
			maxWidth: 500,
			border: 'none'
		}
	},
	box: {
		padding: `${theme.spacing(1.5)} 0`,
		'&>h5': {
			display: 'flex',
			alignItems: 'center',
			gap: '4px'
		}
	},
	guideIdBox: {
		fontWeight: 400,
		paddingTop: '4px'
	},
	carrierContainer: {
		display: 'flex',
		gap: '6px',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	guideData: {
		fontSize: '16px',
		'&>p': {
			display: 'flex',
			alignItems: 'center',
			margin: 0,
			color: 'rgba(255, 255, 255, 0.7)',
			'&>span': {
				fontWeight: 700,
				marginLeft: '6px',
				color: '#FFF'
			},
			'&>svg': {
				marginRight: '12px',
				width: '18px'
			}
		},
		[theme.breakpoints.down(750)]: {
			fontSize: '14px'
		}
	},
	boxWithBorder: {
		filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08))',
		'& p': {
			fontWeight: 500,
			fontSize: '16px'
		}
	},
	loader: {
		minHeight: '20vh'
	}
}));
