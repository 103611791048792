import { Button, ButtonProps, CircularProgress, Tooltip } from '@mui/material';
import { Plus } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useWalletStore from '~store/WalletStore';
import { useStyles } from './styles';
import { resetFlow } from '~store/actions';
import { shallow } from 'zustand/shallow';
import { ReactElement } from 'react';

interface Props extends ButtonProps {
	redirectTo: string;
	onClick?: () => void;
	label?: string;
	isLoading?: boolean;
	disabled?: boolean;
	disabledTooltip?: string;
	icon?: ReactElement;
}

export default function AddButton({
	redirectTo,
	onClick,
	label,
	isLoading,
	disabled,
	disabledTooltip,
	icon,
	...props
}: Props) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const [isPreinvoicesEditing, setIsPreinvoicesEditing, setIsEditing, isEditing] = useWalletStore(
		(state) => [
			state.isPreinvoicesEditing,
			state.setIsPreinvoicesEditing,
			state.setIsEditing,
			state.isEditing
		],
		shallow
	);

	return (
		<Tooltip title={disabled && disabledTooltip ? disabledTooltip : label ? label : ''}>
			<span>
				<Button
					className={classes.root}
					onClick={
						onClick
							? onClick
							: () => {
									isEditing && setIsEditing(false);
									isPreinvoicesEditing && setIsPreinvoicesEditing(false);
									history.push(redirectTo);
									dispatch(resetFlow());
							  }
					}
					variant='contained'
					disabled={disabled}
					// {...props}
				>
					{isLoading ? (
						<CircularProgress size={24} />
					) : (
						<>
							{icon ? icon : <Plus size='16' />}
							<span className={classes.label}>{label ? label : 'Nuevo'}</span>
						</>
					)}
				</Button>
			</span>
		</Tooltip>
	);
}
