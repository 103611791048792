import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Fade } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import DrawContext from '~context/DrawContext';
import { maxWidthContainer, radiusCard } from '~hooks/useStyles';
import { RootStore, useSelector } from '~store/store';
import { resetRate, triggerValidationsRate, initFlow } from '~store/actions';
import { clearErrorsSimulatedUser, triggerErrorSimulatedUser } from '~store/actions/agents';
import ControlBar from '~components/Bar/ControlBar';
import RateOrSendControl from '~components/Bar/RateOrSendControl';
import AddressCardRate from '../Cards/AddressCardRate';
import PackageCard from '../Cards/PackageCard';
import usePermissions from '~hooks/usePermissions';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		padding: theme.spacing(3),
		borderRadius: `0 0 ${radiusCard}px ${radiusCard}px`,
		maxWidth: maxWidthContainer
	}
}));

/**
 * The container for rate
 */
const Rate: React.FC = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const history = useHistory();
	const { currentPackagesRate, canContinueToRateRate } = useSelector(
		(state: RootStore) => state.rate
	);
	const { canPostSimulatedUser } = useSelector((state) => state.simulatedUser);
	const { disableGlobalInsuranceFlow } = useSelector((state) => state.flow);
	const { isRootOrAgent } = usePermissions();
	const [wantsToMove, setWantsToMove] = useState(false);
	const [addInsurance, setAddInsurance] = useState<boolean>(!disableGlobalInsuranceFlow);
	// 1. Change the card
	// 2. Reset errors when the container gets mounted anyway can not move util is valid
	// 3. Init the flow
	// 4. Clear error if is an agent or customer
	const { setRCardStatus } = useContext(DrawContext);
	useEffect(() => {
		dispatch(triggerValidationsRate(false));
		dispatch(initFlow('rate'));
		dispatch(clearErrorsSimulatedUser());
		setRCardStatus('SUMMARY_ADDRESS');
	}, [setRCardStatus, dispatch]);

	// validate before move to rate when rating
	useEffect(() => {
		setWantsToMove(false);
		if (!(wantsToMove && canContinueToRateRate)) {
			return;
		}
		if (!isRootOrAgent) {
			history.push('/resultados');
			return;
		}
		if (canPostSimulatedUser) {
			history.push('/resultados');
		}
	}, [wantsToMove, history, canContinueToRateRate, dispatch, isRootOrAgent, canPostSimulatedUser]);

	const canContinueWhenRating = () => {
		setWantsToMove(true);
		dispatch(triggerValidationsRate(true));
		if (isRootOrAgent) {
			dispatch(triggerErrorSimulatedUser());
		}
	};

	return (
		<Fade in={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className={classes.root}>
				{/* Container */}
				<Grid container alignItems='center'>
					{/* Origen */}
					<Grid item xs={12} md={6}>
						<AddressCardRate for='Origen' />
					</Grid>
					<Grid item xs={12} md={6}>
						<AddressCardRate for='Destino' />
					</Grid>
				</Grid>
				{/* Paquetes */}
				<br />
				<PackageCard
					isRating
					currentPackages={currentPackagesRate}
					setAddInsurance={setAddInsurance}
					addInsurance={addInsurance}
				/>
				{/* Bottom Bar */}
				<ControlBar
					onContinue={canContinueWhenRating}
					close
					onLeftControl={() => {
						dispatch(resetRate());
					}}
					rightText='Continuar'
				>
					<RateOrSendControl />
				</ControlBar>
			</div>
		</Fade>
	);
};

export default Rate;
