import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { TextField, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';

import { useSelector } from '~store/store';
import { setIndicationsPickUp, setExtraIndicationsPickUp } from '~store/actions';
import { radiusCard, useCardStyles } from '~hooks/useStyles';
import { PickupStatus } from '~util/cellStatusColor';
import { useHistory } from 'react-router-dom';
import { HeadingOne } from '~components/Headings';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		height: 270,
		//width: 477,
		'&>*': {
			marginTop: theme.spacing(3)
		},
		borderRadius: `0 ${radiusCard}px ${radiusCard}px 0`
	},
	formWidth: {
		width: '100%',
		maxWidth: 400
	}
}));

/**
 * Las indicaciones en Pick Up
 */
const InfoPickUpCard = ({
	withoutTitle,
	addPickup
}: {
	withoutTitle?: boolean;
	addPickup?: boolean;
}) => {
	const classes = useStyles();
	const history = useHistory();
	const isEditingPickup = useMemo(() => {
		return (
			!history.location.pathname.includes('recolecciones') &&
			history.location.pathname.includes('recoleccion')
		);
	}, [history.location.pathname]);
	const { cardClasses } = useCardStyles();
	const dispatch = useDispatch();

	const { indicationsPickUp, extraIndicationsPickUp, isTouchedPickup, pickUpStatus } = useSelector(
		(state) => state.pickup
	);
	const [extraIndications, setExtraIndications] = useState(extraIndicationsPickUp);
	const [debounceExtraIndications] = useDebounce(extraIndications, 1000);

	useEffect(() => {
		dispatch(setExtraIndicationsPickUp(debounceExtraIndications));
	}, [dispatch, debounceExtraIndications]);

	useEffect(() => {
		// This can happen when going into editing a pickup and the time we update
		// the value in the useEffect hook in UpdateShipment/AddressSelector.tsx
		if (extraIndicationsPickUp !== extraIndications) {
			setExtraIndications(extraIndicationsPickUp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extraIndicationsPickUp]);

	const shouldBeDisabled = useMemo(() => {
		if (isEditingPickup)
			return (pickUpStatus.length > 0 && pickUpStatus !== PickupStatus.PENDING) || false;
		else return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pickUpStatus]);

	return (
		<Grid
			container
			alignItems='center'
			className={
				!withoutTitle ? clsx([classes.root, cardClasses.softColor]) : clsx(cardClasses.card)
			}
		>
			{!withoutTitle && (
				<HeadingOne style={{ width: '100%', marginLeft: '7%' }} text='Indicaciones' />
			)}
			<Grid item xs={12} container justifyContent='center'>
				<Autocomplete
					className={classes.formWidth}
					disabled={shouldBeDisabled || (withoutTitle && !addPickup)}
					openOnFocus
					options={['Puerta Principal', 'Bodega', 'Recepción', 'Otro']}
					getOptionLabel={(option) => option}
					getOptionSelected={(option, value) => option === value}
					onChange={(_, value) => {
						value && dispatch(setIndicationsPickUp(value as string));
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Recolectar en&nbsp;*'
							variant='filled'
							value={indicationsPickUp}
							error={
								!addPickup
									? isTouchedPickup && !Boolean(indicationsPickUp)
									: !Boolean(indicationsPickUp)
							}
							autoComplete='none'
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} style={{ margin: 0 }} container justifyContent='center'>
				<TextField
					onChange={(e) => setExtraIndications(e.target.value)}
					value={extraIndications}
					className={classes.formWidth}
					disabled={shouldBeDisabled || (withoutTitle && !addPickup)}
					error={extraIndications.length > 40}
					label='Comentarios adicionales'
					multiline
					minRows={2}
					maxRows={3}
					helperText={`${extraIndications.length}/40 ${
						extraIndications.length > 40 ? 'Límite excedido' : ''
					}`}
				/>
			</Grid>
		</Grid>
	);
};

export default InfoPickUpCard;
