import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		color: '#3f403f !important',
		backgroundColor: '#E5E5E5 !important',
		paddingLeft: theme.spacing(1.75),
		paddingRight: theme.spacing(1.75),
		minWidth: '100px',
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			color: '#4EC0DF'
		},
		'& svg': {
			marginBottom: '2px'
		},
		'&:disabled': {
			color: 'grey',
			opacity: 0.9
		}
	},
	label: {
		marginLeft: '6px'
	}
}));

export { useStyles };
