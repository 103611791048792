import { Fragment, useEffect, useContext } from 'react';
import { Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import DrawContext from '~context/DrawContext';
import { radiusCard } from '~hooks/useStyles';
import MenuTabs from '~components/Tabs/MenuTabs';
import logo from '../../assets/logo.svg';

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		marginBottom: theme.spacing(15),
		marginTop: '15%',
		maxWidth: 400,
		height: 125
	},
	root: {
		padding: theme.spacing(3),
		borderRadius: `0 0 ${radiusCard}px ${radiusCard}px`
	}
}));

/**
 * The main page, like home, where the just tabs are shown
 */

const MainPage: React.FC = () => {
	const classes = useStyles();
	const { toggleBarLogo, setRCardStatus } = useContext(DrawContext);

	useEffect(() => {
		toggleBarLogo(false);
		setRCardStatus('HIDDEN');
		return () => {
			toggleBarLogo(true);
		};
	}, [toggleBarLogo, setRCardStatus]);

	return (
		<Fragment>
			<Slide appear direction='down' in={true} mountOnEnter unmountOnExit>
				<img src={logo} alt='logo' className={classes.logo} />
			</Slide>
			<br />
			<MenuTabs currentTab={null} />
		</Fragment>
	);
};

export default MainPage;
