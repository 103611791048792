import React, { useMemo } from 'react';
import { HeadingTwo, TextOne } from '~components/Headings';
import { Grid, Link } from '@mui/material';
import { ListItem, List, Divider, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Download } from 'react-feather';
import { FileImg } from '~store/CreateLabelStore';

// const files = [];

export const useStyles = makeStyles((theme: Theme) => ({
	fileContainer: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(1),
		width: '100%'
	},
	linkText: {
		color: '#50aac4',
		textDecoration: 'underline'
	},
	iconContainer: {
		backgroundColor: '#333537',
		borderRadius: '50%',
		padding: theme.spacing(0.5),
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		width: '100%'
	},
	fullWith: {
		width: '100%'
	}
}));

export const FileLink = ({ file, downloadName }: { file: FileImg; downloadName?: string }) => {
	const classes = useStyles();
	const fileName = useMemo(() => file.path.split('/').at(-1), [file]);

	return (
		<ListItem className={classes.fileContainer}>
			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid item>
					<TextOne text={fileName ?? file.path} classes={classes.linkText} />
				</Grid>
				<Grid item>
					<Link
						color='inherit'
						download={downloadName ?? fileName}
						href={file.url}
						target='_blank'
						className={classes.iconContainer}
					>
						<Tooltip title='Descargar archivo'>
							<Download size={18} />
						</Tooltip>
					</Link>
				</Grid>
			</Grid>
		</ListItem>
	);
};

const FilesLabelManual = ({ files }: { files: FileImg[] }) => {
	const classes = useStyles();
	return (
		<Grid item className={classes.fullWith}>
			<HeadingTwo text='Archivos complementarios' />
			<List className={classes.fullWith}>
				{files?.map((file) => (
					<React.Fragment key={file.url}>
						<FileLink file={file} key={file.url} />
						<Divider className={classes.fullWith} />
					</React.Fragment>
				))}
			</List>
		</Grid>
	);
};

export default FilesLabelManual;
