import React from 'react';
// store and context
import { ShipmentSendResponse } from '~store/actions/ActionTypes';

// components
import { Transition, DialogTitle, DialogContent, Dialog } from './ModalComponents';
import { HeadingOne } from '~components/Headings';

// styles and icons
import { Grid } from '@mui/material';
import { useStyles } from './styles';
import ShipmentDetailsContent from './ShipmentDetailsContent';

interface Props {
	isOpen: boolean; // is shown
	handleOpen: (s: boolean) => void;
	data: ShipmentSendResponse | null;
	handleCancel?: () => void;
	isManual?: boolean;
}

/**
 * This is the modal to see the detials of a label
 */

const ShipmentDetailsModal: React.FC<Props> = (props) => {
	const classes = useStyles();

	const handleClose = () => {
		props.handleOpen(false);
	};

	return (
		<Dialog fullScreen TransitionComponent={Transition} onClose={handleClose} open={props.isOpen}>
			<DialogContent dividers>
				<Grid container alignItems='center' justifyContent='center' className={classes.container}>
					<Grid container alignItems='center' justifyContent='center'>
						<Grid
							item
							xs={12}
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'flex-start',
								padding: '16px'
							}}
						>
							<HeadingOne text='Resumen de envío' />
							<DialogTitle onClose={handleClose}>{''}</DialogTitle>
						</Grid>
						<ShipmentDetailsContent
							data={props.data}
							isManual={props.isManual}
							isEditLabel={false}
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default ShipmentDetailsModal;
