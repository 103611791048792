import React, { useEffect, useState, useContext } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Plus as PlusIcon } from 'react-feather';
import { Button, IconButton, Typography, createFilterOptions } from '@mui/material';
import DrawContext from '~context/DrawContext';

import RenderInput from './_RenderInput';
import DropdownPaper from '~components/Forms/DropdownPaper';

import { useAddBtnAutocompleteStyles } from '../../hooks/useStyles';
import { addPkgSend, addPkgRate } from '../../store/actions';
import { Package } from '../../store/actions/ActionTypes';
import { RootStore } from '../../store/store';
import useInputSearchPackages from '../../hooks/useInputSearchPackages';

let showAddBtn = true;
let isRating = false;

const PlusButtonAutoComplete: React.FC<{ children?: React.ReactNode }> = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { btnAutocompleteClasses } = useAddBtnAutocompleteStyles();

	return (
		<DropdownPaper>
			{showAddBtn && (
				<Button
					//variant='outlined'
					fullWidth
					onMouseDown={(e) => {
						e.preventDefault();
					}}
					className={btnAutocompleteClasses.btnAdd}
					onClick={() => {
						if (isRating) {
							dispatch(
								addPkgRate({
									id: new Date().toISOString(),
									pkg_id: '',
									quantity: 1,
									insurance: 0,
									alias: '',
									type: '',
									height: 1,
									width: 1,
									length: 1,
									weight: 1,
									measurement: 'cm',
									merchandise: [],
									content: ''
								} as Package)
							);
						} else {
							history.push('/paquete');
						}
					}}
				>
					<IconButton
						component={Typography}
						onMouseDown={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
							e.preventDefault();
						}}
						disableRipple
						className={btnAutocompleteClasses.iconAdd}
					>
						<PlusIcon />
					</IconButton>
					&nbsp; Agregar nuevo empaque
				</Button>
			)}
			{props.children}
		</DropdownPaper>
	);
};

// Filter search for Contact
const filterOptionsPackage = createFilterOptions<Package>({
	trim: true,
	stringify: (option) => JSON.stringify(option)
});

// to handle add package
const idForNewPackage = 'newPackage';

interface Props {
	label: string; //passed to the render param

	isRating?: boolean; //when ratting
	// isCreating?: boolean;

	/**
	 * Callback for package when is on form
	 */
	onClick?: (pkg: Package) => void;

	shouldDisable?: boolean; //when already adding a package in form
}

/**
 * Autocomplete for search packages
 */
const InputSearchPackage: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { data: packages } = useInputSearchPackages();
	const { isTouchedSend, currentPackagesSend } = useSelector((state: RootStore) => state.send);
	const { isTouchedRate, currentPackagesRate } = useSelector((state: RootStore) => state.rate);
	const { currentFlow } = useSelector((state: RootStore) => state.flow);

	const [pkg, setPkg] = useState<null | Package>(null);
	const [disable, setDisable] = useState(false);
	const [showError, setShowError] = useState(false);

	isRating = Boolean(props.isRating);

	// Clear error
	//validate that there is at least one package
	useEffect(() => {
		if (currentFlow === 'send' && isTouchedSend) {
			setShowError(currentPackagesSend.length === 0);
		} else if (currentFlow === 'rate' && isTouchedRate) {
			setShowError(currentPackagesRate.length === 0);
		}
	}, [
		currentFlow,
		isTouchedSend,
		currentPackagesSend.length,
		isTouchedRate,
		currentPackagesRate.length
	]);

	const { setNotification } = useContext(DrawContext);

	// to give some kind of timeout to setPkg(null) take effect on the input when is on the package form
	useEffect(() => {
		setDisable(Boolean(props.shouldDisable));
	}, [props.shouldDisable]);

	const handleChangePackage = (value: Package | null) => {
		let pkg: Package = {
			id: value!.id,
			pkg_id: new Date().toISOString(),
			alias: value!.alias,
			type: value!.type,
			height: value!.height,
			width: value!.width,
			length: value!.length,
			weight: value!.weight,
			measurement: value!.measurement,
			merchandise: value!.merchandise,
			content: value!.content
		};

		if (props.onClick) {
			props.onClick(pkg);
		} else {
			// Check if trying combine pallet with other package
			let canAddPkg = true;
			const pkgs = isRating ? currentPackagesRate : currentPackagesSend;
			if (pkg.type === 'Tarima')
				canAddPkg = pkgs.length > 0 ? pkgs.every((pkg) => pkg.type === 'Tarima') : true;
			else canAddPkg = pkgs.length > 0 ? pkgs.every((pkg) => pkg.type !== 'Tarima') : true;

			if (!canAddPkg) {
				setNotification({
					severity: 'error',
					message: 'Tarimas deben documentarse por separado'
				});
				return;
			}
			pkg = { ...pkg, quantity: 1 };
			if (isRating) dispatch(addPkgRate(pkg));
			else dispatch(addPkgSend(pkg));
		}
	};

	return (
		<Autocomplete
			size='small'
			disabled={disable}
			selectOnFocus //to helps the user clear the selected value
			clearOnBlur //to helps the user to enter a new value
			autoHighlight // to high always the first option
			openOnFocus
			PaperComponent={PlusButtonAutoComplete}
			filterOptions={(options, params) => {
				const filtered = filterOptionsPackage(options, params);
				if (props.onClick) {
					showAddBtn = false;
					return filtered;
				}

				if (props.isRating) {
					return filtered;
				}

				// Suggest the creation of a new value
				if (params.inputValue !== '' && filtered.length === 0) {
					showAddBtn = false;
					filtered.push({
						id: idForNewPackage,
						pkg_id: '',
						alias: 'Agregar Nuevo Paquete- ' + params.inputValue,
						height: 1,
						length: 1,
						weight: 1,
						width: 1,
						type: '',
						measurement: 'cm',
						content: ''
					} as Package);
				} else {
					showAddBtn = true;
				}

				return filtered;
			}}
			getOptionSelected={(option, value) => option.id === value.id || value.id === idForNewPackage}
			getOptionLabel={(option) => `${option.alias}`}
			options={packages || []}
			renderInput={(params) => (
				<RenderInput
					error={showError}
					errorMessage='Seleccione al menos un paquete'
					params={params}
					for='package'
					{...props}
				/>
			)}
			value={pkg}
			noOptionsText='No se encontraron paquetes'
			onChange={(_, value) => {
				if (typeof value !== 'string') {
					if (value?.id === idForNewPackage) {
						history.push('/paquete');
						return;
					}

					//reset the search
					setPkg(null);
					handleChangePackage(value);
				}
			}}
		/>
	);
};

export default InputSearchPackage;
