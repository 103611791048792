import { useState, useCallback, useContext, useEffect } from 'react';
import axiosInstance from '~util/axios';
import axios from 'axios';
import {
	ShipmentSendResponse,
	NewBusiness,
	ShipmentCompensation,
	ShipmentModality
} from '~store/actions/ActionTypes';
import moment from 'moment';
import DrawContext from '~context/DrawContext';
import { useSelector } from '~store/store';
import usePermissions from '~hooks/usePermissions';

export default function useShipmentDetails() {
	const [selectedBusiness, setSelectedBusiness] = useState<NewBusiness | null>(null);
	const [pdf, setLabelPDF] = useState<string>('');
	const [errorPDF, setErrorPDF] = useState<boolean>(false);
	const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
	const [loadingData, setLoadingData] = useState<boolean>(true);
	const [updatedData, setUpdatedData] = useState<ShipmentSendResponse | null>(null);
	const [businessList, setBusinessList] = useState<NewBusiness[]>();

	const { isRootOrAgent, can } = usePermissions();
	const { simulatedCustomer } = useSelector((state) => state.simulatedUser);
	const { setNotification } = useContext(DrawContext);
	const canHaveBusinessActions = can('canRead.business');

	// fetch details when open
	const fetchGuideDetails = useCallback(
		async (data: ShipmentSendResponse | null, id: string, carrier: string, isManual?: boolean) => {
			try {
				setLoadingData(true);
				setErrorPDF(false);
				setLoadingPDF(true);

				const carrierValue = !!data ? data.carrier : carrier;
				const guideId = !!data ? data.id : id;
				if (!carrierValue || !guideId) return;
				const guideRes = await axiosInstance.get(`api/label/${carrierValue}/${guideId}`);

				setLoadingPDF(false);
				setLabelPDF(guideRes.data.label as string);
				moment.locale('es-MX');

				const resultData = {
					...guideRes.data,
					created_at: moment(guideRes.data.created_at).format('LL')
				};

				setUpdatedData(resultData as ShipmentSendResponse);
			} catch (error) {
				// if fails show the fethed data
				setUpdatedData(data);
				setLoadingPDF(false);
				setErrorPDF(true);
				setLabelPDF('');
				if (axios.isAxiosError(error)) {
					if (error.response) {
						setNotification({
							message: 'Hubo un error al obtener los datos de la guía',
							severity: 'error'
						});
					}
				}
			} finally {
				setLoadingData(false);
			}
		},
		[setNotification]
	);

	const getBusiness = useCallback(
		async (isRootOrAgent: boolean) => {
			try {
				const url = '/api/business';
				const { data } = await axiosInstance.get(
					isRootOrAgent ? `${url}?customer_id=${simulatedCustomer?.id}` : url
				);
				setBusinessList(data.data);
				updatedData &&
					updatedData.business_id &&
					setSelectedBusiness(
						data.data.find((item: NewBusiness) => item.id === updatedData.business_id)
					);
			} catch (error) {
				setBusinessList([]);
				setNotification({
					severity: 'error',
					message: 'Hubo un error al obtener los datos de facturación',
					time: 2000
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updatedData, simulatedCustomer]
	);

	// Get business options for select and selected business for default
	useEffect(() => {
		if ((isRootOrAgent && !simulatedCustomer?.id) || !canHaveBusinessActions) {
			setBusinessList([]);
			return;
		}
		getBusiness(isRootOrAgent);
	}, [simulatedCustomer, isRootOrAgent, canHaveBusinessActions, getBusiness]);

	// Edit selected business for billing
	const putLabelBusiness = useCallback(
		async ({
			business_id,
			compensation,
			modality,
			notes,
			immediate_billing,
			cb
		}: {
			business_id?: number;
			compensation?: ShipmentCompensation;
			modality?: ShipmentModality;
			notes?: string;
			immediate_billing?: boolean;
			cb?: () => void;
		}) => {
			const payload = {
				business_id: business_id || undefined,
				compensation: compensation || undefined,
				modality: modality || undefined,
				notes: notes || undefined,
				immediate_billing: immediate_billing || undefined
			};
			const onlyBusiness =
				!!business_id && !modality && !compensation && !notes && !immediate_billing;
			try {
				await axiosInstance.put(`/api/label/${updatedData?.id}`, payload);
				const selected = businessList?.find((item) => item.id === business_id);
				selected && setSelectedBusiness(selected);
				setNotification({
					severity: 'success',
					message: 'Los datos del envío se actualizaron correctamente',
					time: 2000
				});
				cb && cb();
			} catch (error) {
				setNotification({
					severity: 'error',
					message: `Hubo un error al actualizar ${
						onlyBusiness ? 'la razón social' : 'los datos'
					} del envío`,
					time: 2000
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updatedData, businessList]
	);

	const addCharges = useCallback(async () => {
		try {
			await axiosInstance.put(`/api/billing/label/${updatedData?.id}`);
			setNotification({
				severity: 'success',
				message: 'Los cargos fueron fueron actualizados correctamente',
				time: 2000
			});
		} catch (error) {
			let errorMessage = 'Hubo un error al agregar los cargos';
			if (!axios.isAxiosError(error)) return;

			if (error.response?.data?.description?.length) {
				error.response?.data?.description?.forEach((item: { [key: string]: string }) => {
					if (Object.values(item).includes('The label does not have pending charges')) {
						errorMessage = 'La guía no posee cargos pendientes.';
					}
				});
			}

			setNotification({
				severity: 'error',
				message: errorMessage,
				time: 2000
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedData?.id]);

	// Confirm reused guide
	const confirmReused = useCallback(
		async (reused: boolean, parcel_number: string) => {
			try {
				await axiosInstance.post('/api/label/reused', { parcel_number, reused });
				setNotification({
					severity: 'success',
					message: 'Tu respuesta ha sido enviada',
					time: 2000
				});
			} catch (error) {
				setNotification({
					severity: 'error',
					message: 'Hubo un error al enviar la respuesta',
					time: 2000
				});
			}
		},
		[setNotification]
	);

	return {
		fetchGuideDetails,
		loadingPDF,
		loadingData,
		errorPDF,
		pdf,
		businessList,
		updatedData,
		putLabelBusiness,
		setUpdatedData,
		setSelectedBusiness,
		selectedBusiness,
		addCharges,
		confirmReused
	};
}
