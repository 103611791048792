import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
	Checkbox,
	Button,
	FormControlLabel,
	Grid,
	TextField,
	CircularProgress,
	Typography
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { UserDetails } from '~store/actions/ActionTypes';
import { resetApp } from '~store/actions';
import AuthContext from '~context/AuthContext';
import DrawContext from '~context/DrawContext';
import GradientButton from '~components/Buttons/GradientButton';
import PasswordInput from '~components/Inputs/PasswordInput';
import CopyRight from './_CopyRight';
import LogoMessage from './_LogoMessage';
import axiosInstance, { instanceRate, instancePayment } from '~util/axios';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		border: '1px solid #FFFFFF50',
		marginTop: theme.spacing(3),
		padding: theme.spacing(6),
		maxWidth: `700px !important`,
		borderRadius: `${theme.spacing(1)} !important`
	},
	linkTerms: {
		margin: '15px auto',
		'&>a': {
			textDecoration: 'none',
			color: theme.palette.primary.main
		}
	},
	continueBtn: {
		width: '100%',
		maxWidth: 400,
		borderRadius: theme.spacing(3)
	},
	signIn: {
		width: '100%',
		maxWidth: 400,
		borderRadius: theme.spacing(3),
		border: '1px solid #FFFFFF66',
		height: 48,
		backgroundColor: 'transparent'
	},
	line: {
		height: 1,
		flex: 1,
		backgroundColor: '#FFFFFF50'
	},
	forgotPassword: {
		textDecoration: 'none',
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
	errorMessage: {
		color: '#f44336',
		marginBottom: 20
	}
}));

type Inputs = {
	email: string;
	password: string;
};

interface Props {}

const SignInPage: React.FC<Props> = (props) => {
	const classes = useStyles();

	const history = useHistory();
	const dispatch = useDispatch();
	const { setAuth, setCurrentUser } = useContext(AuthContext);
	const { handleSubmit, errors, control } = useForm<Inputs>();
	const [responseError, setResponseError] = useState<null | string>(null);
	const [isLoading, setIsLoading] = useState(false);
	const { setNotification } = useContext(DrawContext);
	const [rememberSession, setRememberSession] = useState<boolean>(true);

	const onSubmit = async (data: Inputs) => {
		setIsLoading(true);
		try {
			const response = await axiosInstance.post('/api/user/login', {
				...data,
				email: data.email.trim()
			});
			if (response.data.message !== 'UNAUTHORIZED') {
				setResponseError('');
				if (rememberSession) {
					localStorage.setItem('auth', response.data.token);
				}
				axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
				instanceRate.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
				instancePayment.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
				const userDetails = await axiosInstance.get('/api/user');
				setCurrentUser(userDetails.data as UserDetails);
				setAuth(true);
				dispatch(resetApp());
			} else {
				setResponseError('UNAUTHORIZED');
			}
		} catch (error) {
			let errorMessage = 'Algo salió mal en el servidor, intente más tarde.';
			if (axios.isAxiosError(error)) {
				if (error?.message === 'Network Error') {
					setNotification({ message: 'El servidor no esta respondiendo' });
					setIsLoading(false);
					return;
				}
				errorMessage = error?.response?.data?.message;
				setResponseError(errorMessage);
				switch (errorMessage) {
					case 'WRONG_DATA':
					case 'UNAUTHORIZED':
						errorMessage = 'Correo o contraseña incorrectos';
						break;

					default:
						errorMessage = 'Algo salió mal en el servidor, intente más tarde.';
						break;
				}
			}
			setNotification({ message: errorMessage });
			setIsLoading(false);
		}
	};

	return (
		<Grid container justifyContent='center' alignItems='center'>
			{/* The border */}
			<Grid
				item
				xs={12}
				container
				justifyContent='center'
				alignItems='center'
				className={classes.root}
				style={{ marginTop: 24, borderRadius: 8 }}
			>
				{/* Logo and message */}
				<LogoMessage text='Iniciar sesión' />
				{/* Error messages */}
				<Typography variant='h6' component='p' className={classes.errorMessage}>
					{responseError === 'UNAUTHORIZED' || responseError === 'WRONG_DATA'
						? 'Correo o contraseña incorrectos'
						: responseError && 'Ocurrio un error'}
				</Typography>
				{/* The form */}
				<form autoComplete='off'>
					<Grid container justifyContent='center' alignItems='center' spacing={3}>
						<Grid item xs={12} sm={8}>
							<Controller
								name='email'
								rules={{
									required: 'Ingrese su Email',
									pattern: {
										value:
											/^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/i,
										message: 'Ingrese un correo válido'
									}
								}}
								as={
									<TextField
										variant='filled'
										error={Boolean(errors?.email)}
										autoComplete='username'
										fullWidth
										label='Correo&nbsp;*'
									/>
								}
								control={control}
								defaultValue=''
								helperText={errors?.email?.message}
							/>
						</Grid>

						<Grid item xs={12} sm={8}>
							<PasswordInput
								name='password'
								control={control}
								error={'password' in errors}
								rules={{ required: 'Ingrese una contraseña' }}
								helperText={errors?.password?.message}
							/>
						</Grid>

						<Grid container item xs={12} sm={8}>
							<div
								className={classes.forgotPassword}
								onClick={() => history.push('/forgotpassword')}
							>
								Olvidé mi contraseña
							</div>
						</Grid>

						<Grid container item xs={12} sm={8}>
							<div className={classes.linkTerms}>
								Al continuar aceptas los&nbsp;
								<a
									href='https://s3.amazonaws.com/encamino.com/Terminos_y_Condiciones.pdf'
									rel='noreferrer'
									target='_blank'
								>
									Términos y Condiciones
								</a>
								&nbsp;y&nbsp;
								<a
									href='https://s3.amazonaws.com/encamino.com/Aviso_de_Privacidad.pdf'
									rel='noreferrer'
									target='_blank'
								>
									Políticas de privacidad
								</a>
								&nbsp;de Encamino SA de CV.
							</div>
						</Grid>

						<Grid container item xs={12} justifyContent='center' alignItems='center'>
							{isLoading ? (
								<CircularProgress />
							) : (
								<GradientButton
									color='blue'
									type='submit'
									className={classes.continueBtn}
									onClick={handleSubmit(onSubmit)}
								>
									Continuar
								</GradientButton>
							)}
						</Grid>

						<Grid container item xs={12} sm={8} alignItems='center'>
							<FormControlLabel
								value='end'
								control={
									<Checkbox
										color='primary'
										checked={rememberSession}
										onChange={() => setRememberSession((prev) => !prev)}
									/>
								}
								label='Recordar mi sesión'
								labelPlacement='end'
							/>
						</Grid>

						<Grid container item xs={12} sm={8} justifyContent='space-evenly' alignItems='center'>
							<div className={classes.line} />
							&nbsp;
							<h3>¿No tienes cuenta?</h3>&nbsp;
							<div className={classes.line} />
						</Grid>

						<Grid container item xs={12} justifyContent='center' alignItems='center'>
							<Button
								className={classes.signIn}
								onClick={() => (window.location.href = 'https://encamino.com/join/index.html')}
							>
								Registrarme
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>

			<CopyRight />
		</Grid>
	);
};

export default SignInPage;
