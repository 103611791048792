import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserRole } from '~store/actions/ActionTypes';
import AuthContext, { PermissionsKey } from './context/AuthContext';

// Pages
import MainPage from './pages/main/MainPage';
import RateContainer from './components/Containers/Rate';
import SendContainer from './components/Containers/Send';
import PickUpContainer from './components/Containers/PickUp';
import MultiSendLabel from './components/Containers/MultiSendLabel';
// import IncidentResume from '~pages/shipments/Incident/IncidentResume';

// Pages with lazy loading
const CreateOrUpdatePackagePage = React.lazy(
	() => import('./pages/packages/CreateOrUpdatePackagePage')
);
const CreateOrUpdateAddressPage = React.lazy(
	() => import('./pages/address/CreateOrUpdateAddressPage')
);
const SummaryPage = React.lazy(() => import('./pages/summary/SummaryPage'));
const RatePage = React.lazy(() => import('./pages/rate/RatePage'));
const ResultPage = React.lazy(() => import('./pages/results/ResultPage'));
const ConsolidatedPage = React.lazy(() => import('./pages/consolidated/ConsolidatedPage'));
const ShipmentPage = React.lazy(() => import('./pages/shipments/ShipmentPage'));
const UpdateLabelPage = React.lazy(() => import('./pages/shipments/UpdateLabelPage'));
// const ProfilePage = React.lazy(() => import('~pages/profile/ProfilePage'));
// const TutorialsPage = React.lazy(() => import('~pages/tutorials/TutorialsPage'));

// const IncidentForm = React.lazy(() => import('~pages/shipments/Incident/IncidentForm'));
// const IncidentDocuments = React.lazy(
// 	() => import('~pages/shipments/Incident/Forms/IncidentDocuments/IncidentDocuments')
// );

// const EditIncident = React.lazy(
// 	() => import('~pages/shipments/Incident/EditIncident/EditIncident')
// );

const CreateOrUpdateShipmentPage = React.lazy(
	() => import('./pages/shipments/CreateOrUpdateShipmentPage')
);
const BookletPage = React.lazy(() => import('./pages/booklet/BookletPage'));
const CreateOrUpdateUserPage = React.lazy(() => import('./pages/users/CreateOrUpdateUserPage'));
const CreateOrUpdateGroupPage = React.lazy(() => import('./pages/groups/CreateOrUpdateGroupPage'));
const ConsolidatedResultPage = React.lazy(
	() => import('./pages/consolidated/ConsolidatedResultsPage')
);

const CustomerPage = React.lazy(() => import('./pages/customer/CustomerPage'));
// const CustomerFormPage = React.lazy(
// 	() => import('~pages/customer/CreateOrUpdateCustomer/CustomerFormPage')
// );

const CreateOrUpdateClientPage = React.lazy(
	() => import('./pages/customer/CreateOrUpdateCustomer')
);
const CreateOrUpdateProspectPage = React.lazy(
	() => import('./pages/customer/CreateOrUpdateProspect')
);

// const DocumentsPage = React.lazy(() => import('~pages/customer/DocumentsPage/DocumentsPage'));

const AgentsPage = React.lazy(() => import('./pages/agents/AgentsTable'));
const CreateOrUpdateAgentPage = React.lazy(() => import('./pages/agents/CreateOrUpdateAgentPage'));
const CreateDispersionPage = React.lazy(() => import('./pages/Dispersion/DispersionCreate'));
const DispersePage = React.lazy(() => import('./pages/Dispersion/Disperse'));
const CreateIssuePage = React.lazy(() => import('./pages/issues/CreateIssuePage'));

const PackagingPage = React.lazy(() => import('./pages/Packaging/PackagingPage'));
//Wallet
const BookletWallet = React.lazy(() => import('./pages/booklet/BookletWallet'));
const CreateFiscalPage = React.lazy(() => import('./pages/billing/CreateFiscalPage'));
const PreinvoiceFiscalResume = React.lazy(
	() => import('./pages/billing/PreinvoiceFiscalResume/PreinvoiceFiscalResume')
);
const PendingPayments = React.lazy(() => import('./pages/billing/PendingPayments/PendingPayments'));
const CreditNoteRegister = React.lazy(
	() => import('./pages/billing/CreditNoteRegister/CreditNoteRegister')
);

// Balance
const PaymentRegister = React.lazy(() => import('./pages/billing/PaymentRegister/PaymentRegister'));
const DisperseBalance = React.lazy(
	() => import('./pages/Dispersion/DisperseBalance/DisperseBalance')
);

//Rate
const ClientRate = React.lazy(() => import('./components/Forms/Client/ClientRate/ClientRate'));

const CreateGuide = React.lazy(() => import('./components/ShipmentPickUp/CreateGuide/CreateGuide'));
const CreateSimpleAddress = React.lazy(
	() => import('./components/ShipmentPickUp/CreateGuide/SimpleAddress/CreateSimpleAddress')
);

// Dispersion
const PurchaseLabelsPage = React.lazy(() => import('./pages/Dispersion/Purchase/PurchaseLabels'));
const BillingData = React.lazy(() => import('./pages/Dispersion/Billing/PurchaseBilling'));
const PurchaseConfirm = React.lazy(() => import('./pages/Dispersion/Purchase/PurchaseConfirm'));

/**
 * This file contains the routes for all the page, to avoid mispellings
 */

export interface RouteType {
	path: string;
	exact?: boolean;
	name: string;
	component: React.FC<{}>;
	roles?: UserRole[];
	permissionKey: PermissionsKey;
}

// Implement the real route file
export const routes: RouteType[] = [
	// Main Pages
	{ path: '/', exact: true, name: 'MainPage', component: MainPage, permissionKey: 'canRead.app' },
	{
		path: '/carga-masiva',
		exact: true,
		name: 'MultiSendLabel',
		component: MultiSendLabel,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	{
		path: '/enviar',
		exact: true,
		name: 'SendPage',
		component: SendContainer,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	{
		path: '/cotizar',
		exact: true,
		name: 'RatePage',
		component: RateContainer,
		permissionKey: 'canCreateUpdateDelete.rate'
	},
	{
		path: '/recolectar',
		exact: true,
		name: 'PickupPage',
		component: PickUpContainer,
		permissionKey: 'canCreateUpdateDelete.pickup'
	},

	// Flow Pages
	{
		path: '/resultados',
		exact: true,
		name: 'RateResultsPage',
		component: RatePage,
		permissionKey: 'canCreateUpdateDelete.rate'
	},
	{
		path: '/resumen',
		exact: true,
		name: 'SummaryPage',
		component: SummaryPage,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	{
		path: '/consolidado',
		exact: true,
		name: 'ConsolidatedPage',
		component: ConsolidatedPage,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	{
		path: '/consolidados',
		exact: true,
		name: 'ConsolidatedsPage',
		component: ConsolidatedResultPage,
		permissionKey: 'canRead.label'
	},
	{
		path: '/orden',
		exact: true,
		name: 'ResultPage',
		component: ResultPage,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	// Booklet (addresses, packages, users, groups)
	// Dispersion
	{
		path: '/dispersion',
		exact: true,
		name: 'CreateDispersion',
		component: CreateDispersionPage,
		permissionKey: 'canCreateUpdateDelete.dispersion',
		roles: [UserRole.AGENT, UserRole.ROOT]
	},
	{
		path: '/comprar-guias/:id?',
		exact: true,
		name: 'PurchaseLabels',
		component: PurchaseLabelsPage,
		permissionKey: 'canCreateUpdateDelete.disperse'
	},
	{
		path: '/pedido/datos-facturacion',
		exact: true,
		name: 'BillingData',
		component: BillingData,
		permissionKey: 'canCreateUpdateDelete.disperse'
	},
	{
		path: '/pedido/confirmar',
		exact: true,
		name: 'DispersePage',
		component: PurchaseConfirm,
		permissionKey: 'canCreateUpdateDelete.disperse'
	},
	{
		path: '/dispersar/:id?',
		exact: true,
		name: 'Disperse',
		component: DispersePage,
		permissionKey: 'canCreateUpdateDelete.disperse'
	},
	// Address
	{
		path: '/direcciones',
		exact: true,
		name: 'AddressesPage',
		component: BookletPage,
		permissionKey: 'canRead.addresses'
	},
	{
		path: '/direccion/:id?',
		exact: true,
		name: 'CreateOrUpdateAddressPage',
		component: CreateOrUpdateAddressPage,
		permissionKey: 'canCreateUpdateDelete.address'
	},
	// Packages
	{
		path: '/paquetes',
		exact: true,
		name: 'PackagesPage',
		component: BookletPage,
		permissionKey: 'canRead.packages'
	},
	{
		path: '/paquete/:id?',
		exact: true,
		name: 'CreateOrUpdatePackagePage',
		component: CreateOrUpdatePackagePage,
		permissionKey: 'canCreateUpdateDelete.package'
	},
	// Users
	{
		path: '/usuarios',
		exact: true,
		name: 'UsersPage',
		component: BookletPage,
		// roles: [UserRole.AGENT, UserRole.ROOT, UserRole.CUSTOMER],
		permissionKey: 'canRead.users'
	},
	{
		path: '/usuario/:id?',
		exact: true,
		name: 'CreateOrUpdateUserPage',
		component: CreateOrUpdateUserPage,
		// TODO validate that only de admin can manage itself
		// roles: [UserRole.AGENT, UserRole.ROOT, UserRole.CUSTOMER],
		permissionKey: 'canRead.users'
	},
	{
		path: '/grupos',
		exact: true,
		name: 'GruopsPage',
		component: BookletPage,
		permissionKey: 'canRead.groups'
	},
	{
		path: '/grupo/:id?',
		exact: true,
		name: 'CreateOrUpdateGroupPage',
		component: CreateOrUpdateGroupPage,
		permissionKey: 'canCreateUpdateDelete.group'
	},

	// Shipment (rates, shipments, pickups, clarifications)
	// { path: '/cotizaciones', exact: true, name: 'RatesPage', component: ShipmentPage, permissionKey: 'canRead.rate' },
	{
		path: '/envios',
		exact: true,
		name: 'ShipmentsPage',
		component: ShipmentPage,
		permissionKey: 'canRead.label'
	},
	{
		path: '/seguimiento',
		exact: true,
		name: 'ShipmentsTrackingPage',
		component: ShipmentPage,
		permissionKey: 'canRead.label'
	},
	{
		path: '/recolecciones',
		exact: true,
		name: 'PickupsPage',
		component: ShipmentPage,
		permissionKey: 'canRead.pickup'
	},
	// {
	// 	path: '/incidentes',
	// 	exact: true,
	// 	name: 'IncidentTable',
	// 	component: ShipmentPage,
	// 	permissionKey: 'canRead.label'
	// },
	// {
	// 	path: '/registrar-incidente',
	// 	exact: true,
	// 	name: 'IncidentForm',
	// 	component: IncidentForm,
	// 	permissionKey: 'canRead.label'
	// },
	// {
	// 	path: '/registrar-incidente/documentos',
	// 	exact: true,
	// 	name: 'IncidentForm',
	// 	component: IncidentDocuments,
	// 	permissionKey: 'canRead.label'
	// },
	// {
	// 	path: '/registrar-incidente/resumen',
	// 	exact: true,
	// 	name: 'IncidentForm',
	// 	component: IncidentResume,
	// 	permissionKey: 'canRead.label'
	// },
	{
		path: '/dispersiones',
		exact: true,
		name: 'DispersionPage',
		component: ShipmentPage,
		permissionKey: 'canRead.dispersion'
	},
	{
		path: '/recoleccion/:id',
		exact: true,
		name: 'CreateOrUpdatePickupPage',
		component: CreateOrUpdateShipmentPage,
		permissionKey: 'canCreateUpdateDelete.pickup'
	},
	{
		path: '/envio/:id',
		exact: true,
		name: 'UpdateLabelPage',
		component: UpdateLabelPage,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	// PERFIL
	// {
	// 	path: '/perfil/datos',
	// 	exact: true,
	// 	name: 'ProfilePage',
	// 	component: ProfilePage,
	// 	permissionKey: 'canRead.app'
	// },
	// {
	// 	path: '/perfil/tarifas',
	// 	exact: true,
	// 	name: 'ProfilePage',
	// 	component: ProfilePage,
	// 	permissionKey: 'canRead.app'
	// },
	// {
	// 	path: '/perfil/documentos',
	// 	exact: true,
	// 	name: 'ProfilePage',
	// 	component: ProfilePage,
	// 	permissionKey: 'canRead.app'
	// },
	// {
	// 	path: '/tutoriales',
	// 	exact: true,
	// 	name: 'TutorialsPage',
	// 	component: TutorialsPage,
	// 	permissionKey: 'canRead.app'
	// },
	// {
	// 	path: '/perfil/contrato',
	// 	exact: true,
	// 	name: 'ProfilePage',
	// 	component: ProfilePage,
	// 	permissionKey: 'canRead.app'
	// },

	// SALDO
	{
		path: '/historial-saldo',
		exact: true,
		name: 'CreditTable',
		component: ShipmentPage,
		permissionKey: 'canRead.dispersion'
	},
	{
		path: '/registro-saldo/:id?',
		exact: true,
		name: 'PaymentRegister',
		component: PaymentRegister,
		permissionKey: 'canCreateUpdateDelete.disperse'
	},
	{
		path: '/dispersar-saldo',
		exact: true,
		name: 'DisperseBalance',
		component: DisperseBalance,
		permissionKey: 'canCreateUpdateDelete.disperse'
	},

	/**
	 * ==================
	 * ===Admin Routes===
	 * ==================
	 */
	{
		path: '/clientes',
		exact: true,
		name: 'CustomerPage',
		component: CustomerPage,
		roles: [UserRole.AGENT, UserRole.ROOT, UserRole.SELLER],
		permissionKey: 'canRead.client'
	},
	{
		path: '/cliente/:id?',
		exact: true,
		name: 'CreateOrUpdateClientPage',
		component: CreateOrUpdateClientPage,
		roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
		permissionKey: 'canCreateUpdateDelete.client'
	},
	// {
	// 	path: '/cliente/tarifas/:id?',
	// 	exact: true,
	// 	name: 'CustomerFormPage',
	// 	component: CustomerFormPage,
	// 	roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// },
	// {
	// 	path: '/cliente/documentos/:id?',
	// 	exact: true,
	// 	name: 'CustomerFormPage',
	// 	component: CustomerFormPage,
	// 	roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// },
	// {
	// 	path: '/cliente/documento/:id?',
	// 	exact: true,
	// 	name: 'DocumentsPage',
	// 	component: DocumentsPage,
	// 	roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// },
	// {
	// 	path: '/cliente/contrato/:id?',
	// 	exact: true,
	// 	name: 'CustomerFormPage',
	// 	component: CustomerFormPage,
	// 	roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// },
	{
		path: '/tarifas/:id?',
		exact: true,
		name: 'ClientRate',
		component: ClientRate,
		permissionKey: 'canRead.app'
	},
	{
		path: '/prospectos',
		exact: true,
		name: 'ProspectsPage',
		component: CustomerPage,
		roles: [UserRole.AGENT, UserRole.ROOT],
		permissionKey: 'canRead.client'
	},
	{
		path: '/prospecto/:id?',
		exact: true,
		name: 'ProspectsPage',
		component: CreateOrUpdateProspectPage,
		roles: [UserRole.AGENT, UserRole.ROOT],
		permissionKey: 'canCreateUpdateDelete.client'
	},
	// {
	// 	path: '/prospecto/datos/:id?',
	// 	exact: true,
	// 	name: 'CustomerFormPage',
	// 	component: CustomerFormPage,
	// 	roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// },
	// {
	// 	path: '/prospecto/documentos/:id?',
	// 	exact: true,
	// 	name: 'CustomerFormPage',
	// 	component: CustomerFormPage,
	// 	roles: [UserRole.AGENT, UserRole.CUSTOMER, UserRole.ROOT],
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// },
	{
		path: '/agentes',
		exact: true,
		name: 'AgentsPage',
		component: AgentsPage,
		roles: [UserRole.ROOT],
		permissionKey: 'canRead.client'
	},
	{
		path: '/agente/:id?',
		exact: true,
		name: 'CreateOrUpdateAgentPage',
		component: CreateOrUpdateAgentPage,
		roles: [UserRole.ROOT],
		permissionKey: 'canCreateUpdateDelete.client'
	},

	{
		path: '/problema',
		exact: true,
		name: 'CreateIssuePage',
		component: CreateIssuePage,
		// For this page, the permissionKey is irrelevant. We can change this to any
		// other possible key
		permissionKey: 'canCreateUpdateDelete.user'
	},

	{
		path: '/empaques',
		exact: true,
		name: 'PackagingPage',
		component: PackagingPage,
		permissionKey: 'canCreateUpdateDelete.pickup'
	},
	// FACTURACION
	{
		path: '/facturas',
		exact: true,
		name: 'ControlPanel',
		component: BookletWallet,
		permissionKey: 'canRead.business'
	},
	{
		path: '/centro-costos',
		exact: true,
		name: 'BusinessTable',
		component: BookletWallet,
		permissionKey: 'canRead.business'
	},
	{
		path: '/revision',
		exact: true,
		name: 'ReportsTable',
		component: BookletWallet,
		permissionKey: 'canRead.business'
	},
	{
		path: '/pre-facturas',
		exact: true,
		name: 'PreInvoicesTable',
		component: BookletWallet,
		permissionKey: 'canRead.business'
	},
	{
		path: '/facturas',
		exact: true,
		name: 'InvoicesTable',
		component: BookletWallet,
		permissionKey: 'canRead.business'
	},
	{
		path: '/facturas-saldos',
		exact: true,
		name: 'InvoicesTable',
		component: BookletWallet,
		permissionKey: 'canCreateUpdateDelete.client'
	},
	{
		path: '/razon-social/:id?',
		exact: true,
		name: 'CreateFiscalPage',
		component: CreateFiscalPage,
		permissionKey: 'canRead.business'
	},
	{
		path: '/resumen-razon-social/:id?',
		exact: true,
		name: 'PreinvoiceFiscalResume',
		component: PreinvoiceFiscalResume,
		permissionKey: 'canRead.business'
	},
	{
		path: '/pagos',
		exact: true,
		name: 'PendingPayments',
		component: PendingPayments,
		permissionKey: 'canRead.business'
	},
	{
		path: '/nota-credito',
		exact: true,
		name: 'CreditNoteRegister',
		component: CreditNoteRegister,
		permissionKey: 'canRead.business'
	},
	// GUÍA MANUAL
	{
		path: '/guia-manual/:id?',
		exact: true,
		name: 'CreateGuide',
		component: CreateGuide,
		permissionKey: 'canCreateUpdateDelete.label'
	},
	{
		path: '/direccion-simple/:id?',
		exact: true,
		name: 'CreateSimpleAddress',
		component: CreateSimpleAddress,
		permissionKey: 'canRead.app'
	},
	// ADMIN SALDO
	{
		path: '/saldo',
		exact: true,
		name: 'CreditTable',
		component: BookletWallet,
		permissionKey: 'canRead.dispersion'
	}
	// INCIDENTES
	// {
	// 	path: '/incidente/:id',
	// 	exact: true,
	// 	name: 'EditIncident',
	// 	component: EditIncident,
	// 	permissionKey: 'canCreateUpdateDelete.client'
	// }
];

const Routes: React.FC = () => {
	const { currentUser, currentPermissions } = useContext(AuthContext);

	return (
		<Switch>
			{routes.map((r, i) => {
				// This is the only case where we don't validate permissions or role
				if (r.path === '/problema') {
					return <Route key={i} path={r.path} exact={r.exact} component={r.component} />;
				}

				const [permission, module] = r.permissionKey.split('.');
				if (!r.roles) {
					// If no role so it should public
					// @ts-ignore
					if (currentPermissions[permission][module]) {
						return <Route key={i} path={r.path} exact={r.exact} component={r.component} />;
					}
					return null;
				}

				// @ts-ignore
				if (r.roles.includes(currentUser.role) && currentPermissions[permission][module]) {
					return <Route key={i} path={r.path} exact={r.exact} component={r.component} />;
				}
				return null;
			})}
			{/* Default 404 */}
			<Redirect to='/' />
		</Switch>
	);
};

export default React.memo(Routes);
