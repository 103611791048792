import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import InputSearchAddress from '../Inputs/InputSearchAddress';
import InputSearchContact from '../Inputs/InputSearchContact';
import { HeadingOne } from '~components/Headings';
import { useCardStyles } from '../../hooks/useStyles';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3),
		minHeight: 270,
		//width: 477,
		'&>*': {
			marginTop: theme.spacing(3)
		}
	}
}));

interface Props {
	for: 'Origen' | 'Destino';
	isPickingUp?: boolean; // When Picking Up
	reset?: boolean;
}

/**
 * The component AddressCard is used to select a pair of address and contact
 * is used when send or pickup, if you want to rate check <AddressCardRate> component
 */
const AddressCard: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const history = useHistory();

	return (
		<form
			autoComplete='off'
			className={clsx([classes.root, cardClasses.softColor])}
			style={{
				borderRadius: props.isPickingUp
					? '20px 0 0 20px'
					: history.location.pathname.includes('enviar')
					? props.for === 'Destino'
						? '0 20px 20px 0'
						: '20px 0 0 20px'
					: '0'
			}}
		>
			<HeadingOne
				style={{
					textAlign: props.isPickingUp ? 'left' : 'center',
					marginLeft: props.isPickingUp ? '4%' : 0
				}}
				text={props.isPickingUp ? 'Recolectar en' : props.for}
			/>
			<InputSearchAddress
				isPickingUp={props.isPickingUp}
				for={props.for === 'Destino' ? 'addressDestination' : 'addressOrigin'}
				label={
					!history.location.pathname.includes('recolectar') ? 'Dirección ' + props.for : 'Dirección'
				}
				reset={props.reset}
			/>
			<InputSearchContact
				isPickingUp={props.isPickingUp}
				for={props.for === 'Destino' ? 'contactDestination' : 'contactOrigin'}
				label={
					!history.location.pathname.includes('recolectar') ? 'Contacto ' + props.for : 'Contacto'
				}
				reset={props.reset}
			/>
		</form>
	);
};

export default AddressCard;
