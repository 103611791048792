import { DataGrid as BaseDataGrid, DataGridProps } from '@mui/x-data-grid';

import { MUIDataGridTranslations } from '~util/index';
import { Pagination } from './Pagination';
import './DataGrid.css';

type Props = Omit<DataGridProps, 'components' | 'localeText'>;

export default function DataGrid(props: Props) {
	return (
		<BaseDataGrid
			style={{ color: 'white' }}
			disableDensitySelector
			localeText={MUIDataGridTranslations}
			components={{
				Pagination: Pagination
			}}
			{...props}
		/>
	);
}
