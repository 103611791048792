import React, { useContext, useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fade,
	Grid,
	Typography,
	LinearProgress
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
// import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import MenuTabs from '~components/Tabs/MenuTabs';
// import ControlBar from '../../Bar/ControlBar';
import DrawContext from '~context/DrawContext';
// import AuthContext from '~context/AuthContext';
import { radiusCard } from '~hooks/useStyles';
import { RootStore } from '~store/store';
import {
	initFlow,
	// resetMultiSend,
	// resetSend,
	// setCarrierMultiSend,
	// setServiceMultiSend,
	triggerValidationsSend
} from '~store/actions';
// import usePermissions from '~hooks/usePermissions';
import { clearErrorsSimulatedUser } from '~store/actions/agents';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import axios from '~util/axios';
import axiosOriginal from 'axios';
import templateCsv from '../../../assets/csv/template.csv';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Custom Styles
import './styles.css';
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%'
	},
	downloadButons: {
		padding: 12,
		'&>*': {
			marginLeft: 20
		}
	},
	wrapper: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(3),
		borderRadius: `0 0 ${radiusCard}px ${radiusCard}px`
	}
}));

/**
 * The container for send
 */
const MultiSendLabel: React.FC = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	// const history = useHistory();
	// const { isRootOrAgent } = usePermissions();
	// const { currentUser } = useContext(AuthContext);
	// const { carrierMultiSend, serviceMultiSend } = useSelector((state: RootStore) => state.multisend);
	const { simulatedUser } = useSelector((state: RootStore) => state.simulatedUser);

	// const [wantsToMove, setWantsToMove] = useState(false);
	const [open, setOpen] = useState(false);
	const [finished, setFinished] = useState(false);
	const [error, setError] = useState([]);
	const [success, setSuccess] = useState([]);

	// const [carriers, setCarriers] = useState<string[]>([]);
	// const [services, setServices] = useState<string[]>([]);

	// 1. Change the card
	// 2. Reset errors when the container gets mounted anyway can not move util is valid
	// 3. Init the flow
	// 4. Clear error if is an agent or customer
	const { setRCardStatus } = useContext(DrawContext);
	useEffect(() => {
		dispatch(triggerValidationsSend(false));
		dispatch(initFlow('multisend'));
		// TODO add the correct info
		// setRCardStatus('SUMMARY_ADDRESS');
		dispatch(clearErrorsSimulatedUser());
	}, [setRCardStatus, dispatch]);

	// validate before move to rate when sending
	// useEffect(() => {
	// 	setWantsToMove(false);
	// 	// console.log('trying to move send, can', canContinueToRateSend);
	// 	if (!(wantsToMove && canContinueToRateSend)) {
	// 		return;
	// 	}
	// 	if (!isRootOrAgent) {
	// 		history.push('/resultados');
	// 		return;
	// 	}
	// 	if (canPostSimulatedUser) {
	// 		history.push('/resultados');
	// 	}
	// }, [wantsToMove, history, canContinueToRateSend, dispatch, isRootOrAgent, canPostSimulatedUser]);

	// Set unique carriers
	// useEffect(() => {
	// 	setCarriers(['zubut']);
	// let services: string[] = [];
	// if (isRootOrAgent) {
	// 	services = !simulatedUser
	// 		? []
	// 		: simulatedUser.services.map((s) => {
	// 				if (s.type === 'PICKUP') {
	// 					return s.carrier;
	// 				}
	// 				return '';
	// 		  });
	// } else {
	// 	services = currentUser.services.map((s) => {
	// 		if (s.type === 'PICKUP') {
	// 			return s.carrier;
	// 		}
	// 		return '';
	// 	});
	// }
	// setCarriers([...new Set(services)]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [simulatedUser]);
	// }, []);

	// const canContinueWhenSending = () => {
	// 	setWantsToMove(true);
	// 	dispatch(triggerValidationsSend(true));
	// 	if (isRootOrAgent) {
	// 		dispatch(triggerErrorSimulatedUser());
	// 	}
	// };

	return (
		<Fade in={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className={classes.root}>
				<Dialog open={open}>
					<DialogTitle id='alert-dialog-title'>
						<LinearProgress />
						Generando guías
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							Se estan generando las guías, esto puede demorar bastantes minutos. Puede seguir
							utilizando su computadora. <b>No cierre esta pestaña.</b>
						</DialogContentText>
					</DialogContent>
				</Dialog>
				{/* Control Tabs */}
				<MenuTabs currentTab='carga-masiva' />
				{/* Container */}
				<Grid className={classes.wrapper}>
					{/* Subtitle */}
					<Grid item xs={12}>
						<Typography variant='subtitle1'>
							Genera guías de manera masiva por medio de un csv, descarga la plantilla para respetar
							el formato
						</Typography>
					</Grid>
					{/* Services selector */}
					{/* <Grid container item xs={12}>
						<Grid item xs={12} md={6}>
							<Autocomplete
								size='small'
								selectOnFocus
								options={carriers}
								getOptionLabel={(option) => option}
								value={carrierMultiSend}
								onChange={(_: any, value) => {
									if (value) {
										dispatch(setCarrierMultiSend(value));
									} else {
										// clear services
										setServices([]);
										dispatch(resetMultiSend());
									}
								}}
								renderInput={(params) => (
									// <Grid container alignItems='center' justifyContent='center'>
									// 	<Grid item xs={12}>
									<TextField
										{...params}
										label='Transportista'
										// error={isTouchedSimulatedUser && !simulatedCustomer}
										// helperText={isTouchedSimulatedUser && !simulatedCustomer ? 'Selecione una empresa' : ''}
									/>
									// 	</Grid>
									// </Grid>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Autocomplete
								size='small'
								selectOnFocus
								options={services}
								getOptionLabel={(option) => option}
								value={serviceMultiSend}
								onChange={(_: any, value) => {
									dispatch(setServiceMultiSend(value));
								}}
								renderInput={(params) => (
									// <Grid container alignItems='center' justifyContent='center'>
									// 	<Grid item xs={12}>
									<TextField
										{...params}
										label='Servicios'
										// error={isTouchedSimulatedUser && !simulatedCustomer}
										// helperText={isTouchedSimulatedUser && !simulatedCustomer ? 'Selecione una empresa' : ''}
									/>
									// 	</Grid>
									// </Grid>
								)}
							/>
						</Grid>
					</Grid> */}
					{simulatedUser && (
						<div>
							{/* Download resource */}
							<Grid container className={classes.downloadButons} item xs={12} md={10}>
								{/* <Grid item xs={6}> */}
								<Typography variant='subtitle1'>Da clic para descargar</Typography>
								<Button variant='outlined' href={templateCsv}>
									Plantilla
								</Button>
								{/* <Button variant='outlined'>Ejemplo</Button> */}
								{/* </Grid> */}
							</Grid>
							{/* File */}
							<Grid item xs={12} style={{ height: 200 }}>
								<FilePond
									name='csv_file'
									required
									allowMultiple={false}
									maxFiles={1}
									allowFileTypeValidation
									acceptedFileTypes={['text/csv']}
									fileValidateTypeLabelExpectedTypesMap={{ 'text/csv': '.csv' }}
									fileValidateTypeLabelExpectedTypes='Debe ser un csv'
									labelIdle='Arrastra archivo ó <span class="filepond--label-action">Búscar en el dispostivo</span>'
									allowRevert={false}
									// instantUpload={false}
									server={{
										timeout: 99999,
										process: (fieldName, file, metadata, load, error, progress, abort) => {
											setOpen(true);

											const formData = new FormData();
											formData.append('csv_file', file, file.name);
											formData.append('service_id', '1');
											formData.append('carrier', 'zubut');
											formData.append('user_id', simulatedUser.id.toString());

											// aborting the request
											const CancelToken = axiosOriginal.CancelToken;
											const source = CancelToken.source();

											console.log('sending info ');

											axios({
												method: 'POST',
												url: 'api/label/multiple',
												data: formData,
												cancelToken: source.token,
												onUploadProgress: (e) => {
													// updating progress indicator
													progress(e.lengthComputable, e.loaded, e.total);
												}
											})
												.then((response) => {
													// passing the file id to FilePond
													console.log('axios response', response);
													setFinished(true);
													setError(response?.data?.errors ? response.data.errors : []);
													setSuccess(response?.data?.labels ? response?.data?.labels : []);

													setOpen(false);
													// load(response.file);
													load('s');
												})
												.catch((thrown) => {
													if (axiosOriginal.isCancel(thrown)) {
														console.log('Request canceled', thrown.message);
													} else {
														// handle error
														console.log('error uploading file', thrown);
													}
												});
											// Setup abort interface
											return {
												abort: () => {
													source.cancel('Operation canceled by the user.');
													abort();
												}
											};
										}
									}}
								/>
							</Grid>
							{finished && (
								<Grid container item xs={12}>
									<Grid item xs={8}>
										<b>Errores:</b>
										{error?.map((i) => (
											<ul>
												<li>Error en fila {i + 2}</li>
											</ul>
										))}
									</Grid>
									<Grid item xs={8}>
										<b>Correctos</b>: {success.length}
									</Grid>
								</Grid>
							)}
						</div>
					)}
				</Grid>

				{/* Bottom Bar */}
				{/* <ControlBar
					onContinue={canContinueWhenSending}
					close
					onLeftControl={() => {
						dispatch(resetSend());
					}}
					rightText='Continuar'
				> */}
				{/* <RateOrSendControl /> */}
				{/* </ControlBar> */}
			</div>
		</Fade>
	);
};

export default MultiSendLabel;
