import { create } from 'zustand';
import produce from 'immer';

type Notification = {
	message: string;
	date: Date;
	callBack?: () => void;
};

interface NotificationStore {
	notificationsQueue: Notification[];
	setNotificationQueue: (notification: Notification) => void;
	setNotificationDefaultList: (notifications: Notification[]) => void;
	clearNotificationsQueue: () => void;
}

const useNotificationStore = create<NotificationStore>((set) => ({
	notificationsQueue: [],
	setNotificationQueue: (notification: Notification) =>
		set((state) =>
			produce(state, (draft) => {
				draft.notificationsQueue.push(notification);
			})
		),
	clearNotificationsQueue: () =>
		set((state) =>
			produce(state, (draft) => {
				draft.notificationsQueue = [];
			})
		),
	setNotificationDefaultList: (notifications: Notification[]) =>
		set((state) =>
			produce(state, (draft) => {
				draft.notificationsQueue = notifications;
			})
		)
}));

export default useNotificationStore;
