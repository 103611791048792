import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.white.main,
		padding: theme.spacing(3),
		'&>*': {
			marginTop: theme.spacing(2)
		},
		'& button': {
			color: theme.palette.white.main
		},
		'& .Mui-selected': {
			backgroundColor: `${theme.palette.primary.main} !important`
		}
	}
}));

export { useStyles };
