import React, { SetStateAction, Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import DropdownPaper from '~components/Forms/DropdownPaper';
import { updatePkgSend, updatePkgRate } from '../../store/actions';
import { ID } from '~store/actions/ActionTypes';
import { useSelector } from '~store/store';
import axiosInstance from '~util/axios';
import { setPackageContentOptions, setUninsurablePackages } from '~store/actions/FlowActions';

interface Props {
	// Function to pass the selected value
	control: any;
	errors: any;
	defaultValue?: string;
	setValue?: (name: string, value: unknown, config?: { shouldValidate?: boolean }) => void;
	setRequireDescription?: Dispatch<SetStateAction<boolean>>;
	packageId?: ID;
	fieldName?: string;
}

export type PackagingContentItem = {
	content: string;
	can_be_assured?: boolean;
	insurance_description?: string;
};

/**
 * Autocomplete for "Contenido de paquete"
 */
const PackageContentField: React.FC<Props> = ({
	control,
	errors,
	defaultValue,
	setValue,
	setRequireDescription,
	packageId,
	fieldName
}) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { currentFlow } = useSelector((state) => state.flow);
	const [contentOptions, setContentOptions] = useState<PackagingContentItem[]>([]);

	const getPackagingContent = useCallback(async () => {
		try {
			const { data } = await axiosInstance.get('/api/sat/packagingContent');
			dispatch(
				setUninsurablePackages(
					data
						?.filter((item: PackagingContentItem) => !item?.can_be_assured)
						?.map((item: PackagingContentItem) => item.content)
				)
			);
			dispatch(setPackageContentOptions(data?.map((item: PackagingContentItem) => item.content)));
			setContentOptions(data);
		} catch (error) {
			console.error({ error });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getPackagingContent();
	}, [getPackagingContent]);

	return (
		<Controller
			render={({ onChange, ...propsown }) => (
				<Autocomplete
					{...propsown}
					openOnFocus
					disableClearable={
						location.pathname.includes('enviar') || location.pathname.includes('cotizar')
					}
					options={contentOptions}
					getOptionLabel={(option) =>
						option.insurance_description
							? `${option.content} (${option.insurance_description})`
							: option.content
					}
					getOptionSelected={(option, value) => option.content === value.content}
					onChange={(_, data) => {
						onChange(data);
						let newTitle = data ? data.content : '';
						setRequireDescription &&
							setRequireDescription(newTitle === 'Otro' || newTitle === 'Muestras');
						setValue && setValue('content', data, { shouldValidate: true });

						if (!packageId) return;

						if (currentFlow === 'send') {
							dispatch(
								updatePkgSend(packageId, {
									content: data.content
								})
							);
						} else if (currentFlow === 'rate') {
							dispatch(
								updatePkgRate(packageId, {
									content: data.content
								})
							);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							label='Contenido&nbsp;*'
							variant='filled'
							error={Boolean(errors?.package_content)}
							helperText={errors?.package_content?.message}
						/>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			)}
			name={fieldName ?? 'package_content'}
			control={control}
			rules={{ required: true }}
			defaultValue={defaultValue ? { content: defaultValue } : null}
		/>
	);
};

export default PackageContentField;
