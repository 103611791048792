import { useEffect, useState, useContext } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useForm, useWatch } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import LogoMessage from './_LogoMessage';
import axiosInstance from '../../util/axios';
import CopyRight from './_CopyRight';
import GradientButton from '../../components/Buttons/GradientButton';
import PasswordInput from '~components/Inputs/PasswordInput';
import axios from 'axios';
import DrawContext from '~context/DrawContext';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		border: '1px solid #FFFFFF50',
		marginTop: theme.spacing(3),
		padding: theme.spacing(6),
		maxWidth: 700,
		borderRadius: theme.spacing(1)
	},
	linkTerms: {
		margin: '15px auto',
		'&>a': {
			textDecoration: 'none',
			color: theme.palette.primary.main
		}
	},
	continueBtn: {
		width: '100%',
		maxWidth: 400,
		borderRadius: theme.spacing(3)
	}
}));

type Inputs = {
	password: string;
	confirm_password: string;
};

/**
 * When confirm password to validate
 * or when forgot password
 */
const CreatePasswordPage = () => {
	const classes = useStyles();
	const history = useHistory();
	const { validateToken } = useParams<{ validateToken: string }>();
	const { handleSubmit, errors, control, formState, trigger } = useForm<Inputs>();
	const { isSubmitted } = formState;
	const [isLoading, setIsLoading] = useState(false);
	const { setNotification } = useContext(DrawContext);

	const password = useWatch({
		control,
		name: 'password',
		defaultValue: ''
	});

	useEffect(() => {
		localStorage.removeItem('auth');
	}, []);

	useEffect(() => {
		if (isSubmitted) {
			trigger('confirm_password');
		}
	}, [isSubmitted, trigger, password]);

	const onSubmit = async (data: Inputs) => {
		setIsLoading(true);
		//console.log('data', data);
		try {
			//const response =
			await axiosInstance.post(`/api/user/validate/${validateToken}`, {
				password: data.password,
				confirm_password: data.confirm_password
			});
			//console.log('response', response.data);
			history.replace('/auth');
		} catch (error) {
			let errorMessage = 'Algo salió mal, inténtalo de nuevo más tarde';
			console.log({ error });
			if (axios.isAxiosError(error)) {
				errorMessage =
					error?.response?.data?.message === 'TOKEN_EXPIRED'
						? 'Este enlace ya no es válido'
						: error?.response?.data?.message === 'WRONG_DATA'
						? 'La contraseña debe tener un mínimo de 8 caracteres y contener al menos una mayúscula, una minúscula, un número y un símbolo.'
						: 'Hubo un problema al actualizar la contraseña';
			}
			setIsLoading(false);
			setNotification({
				message: errorMessage,
				severity: 'error'
			});
		}
	};

	return (
		<Grid container justifyContent='center' alignItems='center'>
			{/* The border */}
			<Grid
				item
				xs={12}
				container
				justifyContent='center'
				alignItems='center'
				className={classes.root}
			>
				{/* Logo and message */}
				<LogoMessage text='Bienvenido' subtitle='' />
				{/* The form */}
				<form autoComplete='off'>
					<Grid container justifyContent='center' alignItems='center' spacing={3}>
						<Grid item xs={12} sm={8}>
							<Typography align='center' variant='h6' component='p'>
								Crear contraseña
							</Typography>
						</Grid>

						{/* <Grid item xs={12} sm={8}>
							<Typography align='center' style={{ color: 'red' }} component='p'>
								{responseError === 'TOKEN_EXPIRED' && 'Este enlace ya no es válido'}
								{responseError === 'WRONG_DATA' &&
									'La contraseña debe tener un mínimo de 8 caracteres y contener al menos una mayúscula, una minúscula, un número y un símbolo.'}
							</Typography>
						</Grid> */}

						<Grid item xs={12} sm={8}>
							<PasswordInput
								name='password'
								rules={{
									required: true,
									pattern: {
										value:
											/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~])[a-zA-Z0-9!"#\$%&'\(\)\*\+,-\.\/:;<=>\?@[\]\^_`\{\|}~]{8,}$/i,
										message: 'Combinación de letras mayúsculas, minúsculas, números y símbolos'
									},
									minLength: { value: 8, message: 'Mínimo 8 caracteres' }
								}}
								control={control}
								error={'password' in errors}
								helperText={
									errors?.password
										? errors.password.message
										: 'Mínimo 8 caracteres, con combinación de letras mayúsculas, minúsculas, números y símbolos'
								}
							/>
						</Grid>

						<Grid item xs={12} sm={8}>
							<PasswordInput
								name='confirm_password'
								rules={{
									required: 'Confirme su contraseña',
									validate: (p: string) => {
										if (password !== p) {
											return 'Las contraseñas son distintas';
										}
										return true;
									}
								}}
								control={control}
								error={'confirm_password' in errors}
								helperText={errors?.confirm_password?.message}
							/>
						</Grid>

						<Grid item xs={12} sm={8}>
							<div className={classes.linkTerms}>
								Al continuar aceptas los&nbsp;
								<a
									href='https://s3.amazonaws.com/encamino.com/Terminos_y_Condiciones.pdf'
									rel='noreferrer'
									target='_blank'
								>
									Términos y Condiciones
								</a>
								&nbsp;y&nbsp;
								<a
									href='https://s3.amazonaws.com/encamino.com/Aviso_de_Privacidad.pdf'
									rel='noreferrer'
									target='_blank'
								>
									Políticas de privacidad
								</a>
								&nbsp;de Encamino SA de CV.
							</div>
						</Grid>

						<Grid container item xs={12} justifyContent='center' alignItems='center'>
							{isLoading ? (
								<CircularProgress />
							) : (
								<GradientButton
									color='blue'
									type='submit'
									className={classes.continueBtn}
									onClick={handleSubmit(onSubmit)}
								>
									Continuar
								</GradientButton>
							)}
						</Grid>
					</Grid>
				</form>
			</Grid>
			<CopyRight />
		</Grid>
	);
};

export default CreatePasswordPage;
