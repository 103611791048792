import { Grid } from '@mui/material';
import React from 'react';
import { PermissionsKey } from '~context/AuthContext';
import OnlyIf from '~components/Guard/OnlyIf';
import { AddButton } from './Controls/AddButton';

interface Props {
	rows?: any[];
	linkToAdd: string;
	setFilteredRows?: (rows: any[]) => void;
	permission?: PermissionsKey;
}

const TableControls: React.FC<Props> = (props) => {
	return (
		<Grid container justifyContent='flex-end'>
			{/* @@@ Remove the conditional */}
			{props.permission ? (
				<OnlyIf permission={props.permission}>
					{/* <Button
						className={classes.newBtn}
						onClick={() => {
							history.push(props.linkToAdd);
							dispatch(resetFlow());
						}}
					>
						<Plus size="15" />
						&nbsp;Nuevo
					</Button> */}
					<AddButton redirectTo={props.linkToAdd} />
				</OnlyIf>
			) : null}
			{/* {props.setFilteredRows && (
				<div className={classes.search}>
					<div className={classes.searchIcon}>
						<Search />
					</div>
					<InputBase
						placeholder='Buscar…'
						onChange={handleFilter}
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
				</div>
			)} */}
		</Grid>
	);
};

export default TableControls;
