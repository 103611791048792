import { Grid, LinearProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		'& > *': {
			//marginTop: theme.spacing(2)
		}
	},
	bkLine: {
		backgroundColor: 'unset'
	}
}));

const LoaderPage: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid container justifyContent='center' alignItems='center'>
			<div className={classes.root}>
				<LinearProgress
					classes={{
						colorPrimary: classes.bkLine
					}}
				/>
			</div>
		</Grid>
	);
};

export default LoaderPage;
