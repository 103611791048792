import React from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		color: `${theme.palette.white.main} !important`,
		backgroundColor: '#353535 !important',
		'& > ul > li > *': {
			backgroundColor: '#353535 !important',
			color: `${theme.palette.white.main} !important`
		},
		'& > *': {
			color: `${theme.palette.white.main} !important`,
			fontSize: '14px'
			// opacity: 0.7
		}
	}
}));

const DropdownPaper = ({ children }: { children: React.ReactNode }) => {
	const classes = useStyles();
	return <Paper className={classes.container}>{children}</Paper>;
};

export default DropdownPaper;
