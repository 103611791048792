import { FC } from 'react';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { PDFObject } from 'react-pdfobject';
import { radiusCard } from '~hooks/useStyles';
import { TextTwo } from '~components/Headings';

const useStyles = makeStyles((theme: Theme) => ({
	labelContainer: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: radiusCard,
		padding: `${theme.spacing(2)} 0`
	},
	noPDFTextContainer: {
		fontStyle: 'italic',
		backgroundColor: '#2a2a2e',
		minHeight: '300px',
		borderRadius: '4px'
	}
}));

interface Props {
	pdf: string;
	isManual?: boolean;
}

const LabelContainer: FC<Props> = ({ pdf, isManual }) => {
	const classes = useStyles();

	return (
		<Grid item xs={12} container justifyContent='center' className={classes.labelContainer}>
			<Grid item xs={12}>
				{pdf === '' ? (
					<Grid
						item
						container
						justifyContent='center'
						alignItems='center'
						className={classes.noPDFTextContainer}
					>
						<TextTwo text='Aguarde unos minutos, es posible que la guía todavía se esté generando' />
					</Grid>
				) : !isManual ? (
					<PDFObject url={`data:application/pdf;base64,${pdf}`} height='600px' />
				) : pdf.includes('pdf') ? (
					<PDFObject url={pdf} height='600px' />
				) : (
					<img style={{ maxWidth: '600px' }} src={pdf} alt='archivo complementario' />
				)}
			</Grid>
		</Grid>
	);
};

export default LabelContainer;
