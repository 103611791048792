import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Home, CheckCircle, MapPin, Package } from 'react-feather';
import { RootStore } from '../../store/store';

const useStyles = makeStyles((theme: Theme) => {
	return {
		font: {
			fontWeight: 'bold',
			fontSize: 15
		}
	};
});

const RateOrSendControl: React.FC = () => {
	const classes = useStyles();

	const { currentFlow } = useSelector((state: RootStore) => state.flow);
	const { originAddressSend, destinationAddressSend, currentPackagesSend } = useSelector(
		(state: RootStore) => state.send
	);
	const { originAddressRate, destinationAddressRate, currentPackagesRate } = useSelector(
		(state: RootStore) => state.rate
	);
	const { labelsPickup, addressPickUp, datePickUp, endTimePickUp, startTimePickUp } = useSelector(
		(state: RootStore) => state.pickup
	);

	const [origin, setOrigin] = useState<boolean>(false);
	const [destination, setDestination] = useState<boolean>(false);
	const [pkgs, setPackages] = useState<boolean>(false);

	useEffect(() => {
		setOrigin(
			Boolean(
				(originAddressRate && currentFlow === 'rate') ||
					(currentFlow === 'send' && originAddressSend && originAddressSend.contact) ||
					(currentFlow === 'pickup' && addressPickUp && addressPickUp.contact)
			)
		);
	}, [originAddressRate, originAddressSend, addressPickUp, currentFlow]);

	useEffect(() => {
		setDestination(
			Boolean(
				(destinationAddressRate && currentFlow === 'rate') ||
					(currentFlow === 'send' && destinationAddressSend) ||
					(currentFlow === 'pickup' && datePickUp && endTimePickUp && startTimePickUp)
			)
		);
	}, [
		destinationAddressRate,
		destinationAddressSend,
		currentFlow,
		datePickUp,
		endTimePickUp,
		startTimePickUp
	]);

	useEffect(() => {
		setPackages(
			(currentPackagesRate.length > 0 && currentFlow === 'rate') ||
				(currentFlow === 'send' && currentPackagesSend.length > 0) ||
				(currentFlow === 'pickup' && labelsPickup.length > 0)
		);
	}, [currentPackagesRate.length, currentPackagesSend.length, labelsPickup.length, currentFlow]);

	const firstStep = useMemo(
		() => (currentFlow === 'pickup' ? pkgs : origin),
		[pkgs, origin, currentFlow]
	);
	const lastStep = useMemo(
		() => (currentFlow === 'pickup' ? origin : pkgs),
		[pkgs, origin, currentFlow]
	);

	return (
		<Grid container justifyContent='center' alignItems='center' className={classes.font}>
			<Grid item container justifyContent='center' alignItems='center' xs={4}>
				{firstStep ? (
					<CheckCircle color='#6FE46D' />
				) : currentFlow === 'pickup' ? (
					<Package />
				) : (
					<Home />
				)}
				&nbsp;&nbsp;{currentFlow === 'pickup' ? 'Guías' : 'Origen'}
			</Grid>
			<Grid item container justifyContent='center' alignItems='center' xs={4}>
				{destination ? <CheckCircle color='#6FE46D' /> : <MapPin />}
				&nbsp;&nbsp;{currentFlow === 'pickup' ? 'Dirección' : 'Destino'}
			</Grid>
			<Grid item container justifyContent='center' alignItems='center' xs={4}>
				{lastStep ? (
					<CheckCircle color='#6FE46D' />
				) : currentFlow === 'pickup' ? (
					<Home />
				) : (
					<Package />
				)}
				&nbsp;&nbsp;{currentFlow === 'pickup' ? 'Indicaciones' : 'Empaques'}
			</Grid>
		</Grid>
	);
};

export default RateOrSendControl;
