import { useState, useCallback, useMemo } from 'react';
import { Controller, Control } from 'react-hook-form';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		zIndex: 0
	},
	eyeContainer: {
		position: 'absolute',
		top: '28px',
		right: '12px',
		transform: 'translateY(-50%)',
		zIndex: 5,
		background: 'transparent',
		outline: 'none',
		border: 'none',
		color: 'white'
	}
}));

interface PasswordInputProps {
	control: Control;
	error: boolean;
	name: string;
	rules: any;

	defaultValue?: string;
	disabled?: boolean;
	helperText?: string;
}

export default function PasswordInput({
	disabled,
	control,
	name,
	defaultValue,
	rules,
	helperText,
	error
}: PasswordInputProps) {
	const styles = useStyles();
	const [show, setShow] = useState(false);
	const toggle = useCallback(
		(e: any) => {
			e.preventDefault();
			setShow((prev) => !prev);
		},
		[setShow]
	);
	const type = useMemo(() => (show ? 'text' : 'password'), [show]);
	const icon = useMemo(() => {
		return (
			<button type='button' className={styles.eyeContainer} onClick={toggle}>
				{show ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
			</button>
		);
	}, [show, styles, toggle]);

	return (
		<Controller
			name={name}
			rules={rules}
			as={
				<div className={styles.container}>
					<TextField
						variant='filled'
						type={type}
						autoComplete='new-password'
						error={error}
						fullWidth
						label='Contraseña&nbsp;*'
						helperText={helperText}
					/>

					{icon}
				</div>
			}
			control={control}
			defaultValue=''
		/>
	);
}
