import produce from 'immer';
import moment from 'moment';
import {
	FlowActions,
	Flow,
	INIT_FLOW,
	RESET_FLOW,
	ADD_ADDRESS_IN_FLOW,
	FILTER_CARRIER_FLOW,
	SORT_CARRIER_FLOW,
	DISABLE_GLOBAL_INSURANCE_FLOW,
	ENABLE_GLOBAL_INSURANCE_FLOW,
	SHOW_GLOBAL_INSURANCE_FLOW,
	SET_UNIQUE_CARRIER_SERVICES_FLOW,
	SET_FILTERED_SERVICES,
	ServiceResponse,
	SET_UNINSURABLE_PACKAGES,
	SET_CONTENT_OPTIONS
} from '../actions/ActionTypes';

export type FlowState = {
	currentFlow: Flow | null;

	// TODO move to app ?
	// If you add an address while sending, none applies when you clic the add btn
	// FIXME update: now it is possible
	addingAddressTarget: 'origin' | 'destination' | 'none';

	// When results of rates
	disableGlobalInsuranceFlow: boolean;
	showGlobalInsuranceFlow: boolean;
	uniqueCarriers: string[];
	filteredServicesFlow: ServiceResponse[];
	isShowingDHL: boolean;
	indexDHL: number;
	uninsurablePackages: string[];
	contentOptions: string[];
};

const initialState: FlowState = {
	currentFlow: null,
	addingAddressTarget: 'none',

	// Rate Result Page
	disableGlobalInsuranceFlow: true,
	showGlobalInsuranceFlow: false,
	uniqueCarriers: [],
	filteredServicesFlow: [],
	isShowingDHL: false,
	indexDHL: -1,
	uninsurablePackages: [],
	contentOptions: []
};

const FlowReducer = (state: FlowState = initialState, action: FlowActions): FlowState => {
	let nextState: FlowState;
	switch (action.type) {
		case INIT_FLOW:
			nextState = produce(state, (draft) => {
				draft.currentFlow = action.flow;
			});
			break;
		case RESET_FLOW:
			return initialState;
		case ADD_ADDRESS_IN_FLOW:
			nextState = produce(state, (draft) => {
				draft.addingAddressTarget = action.for;
			});
			break;
		case DISABLE_GLOBAL_INSURANCE_FLOW:
			nextState = produce(state, (draft) => {
				draft.disableGlobalInsuranceFlow = true;
			});
			break;
		case ENABLE_GLOBAL_INSURANCE_FLOW:
			nextState = produce(state, (draft) => {
				draft.disableGlobalInsuranceFlow = false;
			});
			break;
		case SHOW_GLOBAL_INSURANCE_FLOW:
			nextState = produce(state, (draft) => {
				draft.showGlobalInsuranceFlow = action.show;
			});
			break;
		case FILTER_CARRIER_FLOW:
			nextState = produce(state, (draft) => {
				draft.filteredServicesFlow = action.services.filter((fs) => fs.carrier === action.filter);
				draft.isShowingDHL = action.filter === 'dhl';
			});
			break;
		case SORT_CARRIER_FLOW:
			nextState = produce(state, (draft) => {
				switch (action.sort) {
					case 0: //price
						draft.filteredServicesFlow = draft.filteredServicesFlow.sort(
							(a, b) => a.total_cost - b.total_cost
						);
						break;
					case 1: //date
						draft.filteredServicesFlow = draft.filteredServicesFlow.sort((a, b) => {
							if (moment(a.estimated_delivery_date).isSame(moment(b.estimated_delivery_date))) {
								return 0;
							} else if (
								moment(a.estimated_delivery_date).isBefore(moment(b.estimated_delivery_date))
							) {
								return -1;
							}
							return 1;
						});
						break;
				}
			});
			break;
		case SET_UNIQUE_CARRIER_SERVICES_FLOW:
			nextState = produce(state, (draft) => {
				draft.uniqueCarriers = action.carriers;
				draft.indexDHL = action.carriers.findIndex((c) => c === 'dhl');
			});
			break;
		case SET_FILTERED_SERVICES:
			nextState = produce(state, (draft) => {
				draft.filteredServicesFlow = action.services;
				draft.isShowingDHL = false;
			});
			break;
		case SET_CONTENT_OPTIONS:
			nextState = produce(state, (draft) => {
				draft.contentOptions = action.options;
			});
			break;
		case SET_UNINSURABLE_PACKAGES:
			nextState = produce(state, (draft) => {
				draft.uninsurablePackages = action.uninsurablePackages;
			});
			break;
		default:
			return state;
	}
	return nextState;
};

export default FlowReducer;
