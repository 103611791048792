import { Button } from '@mui/material';
import React from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

interface Props {
	onClick?: () => void;
	[x: string]: any;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			display: 'flex',
			flexDirection: 'column',
			'& p': {
				display: 'flex',
				flexDirection: 'column',
				'&:hover': {
					color: theme.palette.primary.main
				},
				fontSize: 11
			},
			border: 'none !important'
		}
	};
});

/**
 * Este botón tiene por defecto el icono arriba y el texto abajo
 */

const CustomIconButton: React.FC<Props> = (props) => {
	const classes = useStyles();

	return (
		<Button {...props} onClick={props.onClick} color='inherit' className={classes.root}>
			{props.children}
		</Button>
	);
};

export default CustomIconButton;
