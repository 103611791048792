import { withStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Switch, { /*SwitchClassKey,*/ SwitchProps } from '@material-ui/core/Switch';
// interface Styles extends Partial<Record<SwitchClassKey, string>> {
// 	focusVisible?: string;
// }

interface Props extends SwitchProps {
	classes: any; //Styles;
	useRed?: boolean;
}

const IOSSwitch = withStyles((theme: Theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#52d869',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#52d869',
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],

		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	redTrack: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.error.dark,

		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {
		// backgroundColor: 'green'
	},
	focusVisible: {}
}))(({ classes, useRed, ...props }: Props) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: useRed ? (props.checked ? classes.track : classes.redTrack) : classes.track,
				checked: classes.checked
			}}
			{...props}
		/>
	);
});

export default IOSSwitch;
