import React, { useContext, ReactNode } from 'react';
import clsx from 'clsx';
import { Grid, Divider, CircularProgress } from '@mui/material';
import { ArrowLeft, ArrowRight, X } from 'react-feather';
import DrawContext from '~context/DrawContext';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// https://glassmorphism.com/

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			height: 65,
			position: 'fixed',
			bottom: 30,

			width: '80%',
			minWidth: 200,
			maxWidth: 850,
			background: 'rgba(66, 66, 66, 0.95)',
			border: '1px solid rgba(255, 255, 255, 0.2)',
			boxSizing: 'border-box',
			borderRadius: theme.spacing(1),
			//backdropFilter: 'blur(5px)', // Disabled because not supported
			left: '50%',
			transform: 'translateX(-63%)',

			'@media screen and (max-width: 1400px)': {
				transform: 'translateX(-50%)'
			},

			'& hr': {
				margin: theme.spacing(-2, 0.5)
			},
			'& button': {
				color: theme.palette.white.main
			},

			zIndex: 1201
		},

		onHiddenCard: {
			transform: 'translateX(-48%)'
		},

		continueBtn: {
			background: 'linear-gradient(180deg, #57BBD8 0%, #0088D5 100%), #57BBD8',
			border: '1px solid rgba(199, 199, 199, 0.1)',
			boxSizing: 'border-box',
			boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
			borderRadius: '0px 8px 8px 0px',
			height: '100%',
			//minWidth: 150,
			width: '100%'
		},
		cursor: {
			cursor: 'pointer'
		},
		font: {
			fontWeight: 'bold',
			fontSize: 15
		},
		disabled: {
			opacity: 0.8,
			cursor: 'auto',
			color: '#f2f2f2'
		}
	};
});

interface Props {
	onContinue: () => void;
	close?: boolean; // to render close btn insted of left arrow
	isLoading?: boolean; // to change the right text
	onLeftControl: () => void;
	rightText: string;
	children: ReactNode;
	disabled?: boolean;
}

/**
 * This is the wrapper for controls in the Control Bar
 */

const ControlBar: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { rCardStatus } = useContext(DrawContext);

	return (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			className={clsx([
				classes.root,
				classes.font,
				rCardStatus === 'HIDDEN' && classes.onHiddenCard
			])}
		>
			{/* Left most */}
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				item
				xs={1}
				className={classes.cursor}
				onClick={props.onLeftControl}
			>
				{props.close ? <X size={30} /> : <ArrowLeft size={30} />}
				&nbsp;&nbsp;&nbsp;
				<Divider orientation='vertical' flexItem />
			</Grid>
			{/* Controls */}
			<Grid item xs={9}>
				{props.children}
			</Grid>
			{/* Continue */}
			<Grid
				container
				justifyContent='space-around'
				alignItems='center'
				item
				xs={2}
				className={clsx([
					classes.continueBtn,
					classes.cursor,
					classes.font,
					props.disabled ? classes.disabled : ''
				])}
				onClick={() => {
					if (props.disabled) return;
					props.onContinue();
				}}
			>
				{props.isLoading ? <CircularProgress /> : <p>&nbsp;&nbsp;&nbsp;{props.rightText}</p>}
				<ArrowRight size={30} />
			</Grid>
		</Grid>
	);
};

export default ControlBar;
