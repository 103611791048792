import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import { Grid, TextField, Typography, Fade } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
// TODO change to date-fns instead of moment
import moment from 'moment';
import { setHours, setMinutes } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import { GridSelectionModel } from '@mui/x-data-grid';
// Components
import AddressCard from '../Cards/AddressCard';
import InfoPickUpCard from '../Cards/InfoPickUpCard';
import ControlBar from '~components/Bar/ControlBar';
import RateOrSendControl from '~components/Bar/RateOrSendControl';
import TableShipmentSend from '~components/Tables/ShipmentTables/TableShipmentSend';
import { HeadingOne } from '~components/Headings';
// Hooks
import { maxWidthContainer, useCardStyles } from '../../hooks/useStyles';
import { radiusCard } from '~hooks/useStyles';
import usePermissions from '~hooks/usePermissions';
import useLabels from '~hooks/useLabels';
import usePickupAvailability from '~hooks/usePickupAvailability';
import DrawContext from '../../context/DrawContext';
// Store and utils
import {
	setDatePickUp,
	setTimePickUp,
	triggerValidationsPickup,
	resetPickup,
	initFlow,
	resetLabelsPickup
} from '../../store/actions';
import { RootStore } from '../../store/store';
import { clearErrorsSimulatedUser, triggerErrorSimulatedUser } from '~store/actions/agents';
import { ShipmentSendResponse } from '~store/actions/ActionTypes';
import { setLabelsPickup } from '~store/actions';
import { CARRIERS } from '~util/logos';

const useStyles = makeStyles((theme: Theme) => ({
	card: {
		padding: theme.spacing(3)
	},
	inputTimes: {
		maxWidth: 150,
		width: '100%'
	},
	linkTerms: {
		'&>a': {
			textDecoration: 'none',
			color: theme.palette.primary.main
		}
	},
	terms: {
		margin: `${theme.spacing(1)} ${theme.spacing(10)}`
	},
	root: {
		padding: theme.spacing(3),
		borderRadius: `${radiusCard}px`,
		maxWidth: maxWidthContainer
	}
}));

/**
 * The container to PickUp
 */
const PickUp: React.FC = () => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		datePickUp,
		endTimePickUp,
		startTimePickUp,
		isTouchedPickup,
		canContinueToSummaryPickUp,
		addressPickUp,
		labelsPickup,
		carrierPickup
	} = useSelector((state: RootStore) => state.pickup);
	const { canPostSimulatedUser } = useSelector((state: RootStore) => state.simulatedUser);
	const { isRootOrAgent } = usePermissions();
	const [wantsToMove, setWantsToMove] = useState(false);
	const [selectionModelPickUp, setSelectionModelPickUp] = useState<GridSelectionModel>([]);

	const infoRef = useRef<HTMLElement | null>(null);
	const { data } = useLabels(
		isRootOrAgent ? ['user_id', 'carrier', 'for_pickup'] : ['carrier', 'for_pickup']
	);
	const { checkFedexAvailability, fedexAvailability } = usePickupAvailability();

	// 1. Change the card
	// 2. Reset errors when the container gets mounted anyway can not move util is valid
	// 3. Init the flow
	// 4. Clear error if is an agent or customer
	const { setRCardStatus } = useContext(DrawContext);
	useEffect(() => {
		dispatch(triggerValidationsPickup(false));
		dispatch(initFlow('pickup'));
		setRCardStatus('HIDDEN');
		dispatch(clearErrorsSimulatedUser());
	}, [setRCardStatus, dispatch]);

	const isFedex = useMemo(() => carrierPickup === CARRIERS?.FEDEX, [carrierPickup]);
	const isPaquetexpress = useMemo(() => carrierPickup === CARRIERS?.PAQUETEXPRESS, [carrierPickup]);

	const maxTimeStart = useMemo(
		() =>
			isFedex && fedexAvailability && datePickUp
				? setHours(
						setMinutes(
							new Date(datePickUp),
							parseInt(
								fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.earliest_times
									?.at(-1)
									?.slice(3, 5) ?? '0'
							)
						),
						parseInt(
							fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.earliest_times
								?.at(-1)
								?.slice(0, 2) ?? '0'
						)
				  )
				: setHours(setMinutes(new Date(), 0), 22),
		[datePickUp, fedexAvailability, isFedex]
	);
	const minTimeStart = useMemo(
		() =>
			!datePickUp
				? moment().toDate()
				: isFedex && fedexAvailability
				? setHours(
						setMinutes(
							new Date(datePickUp),
							parseInt(
								fedexAvailability[
									moment(datePickUp).format('YYYY-MM-DD')
								]?.earliest_times[0]?.slice(3, 5) ?? '0'
							)
						),
						parseInt(
							fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.earliest_times[0]?.slice(
								0,
								2
							)
						)
				  )
				: moment(new Date()).format('YYYY-MM-DD') === moment(datePickUp).format('YYYY-MM-DD')
				? moment().toDate()
				: moment(datePickUp).toDate(),
		[datePickUp, fedexAvailability, isFedex]
	);
	const minTimeEnd = useMemo(
		() =>
			isFedex && fedexAvailability && datePickUp
				? setHours(
						setMinutes(
							new Date(datePickUp),
							parseInt(
								fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.latest_times[0]?.slice(
									3,
									5
								)
							)
						),
						parseInt(
							fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.latest_times[0]?.slice(
								0,
								2
							)
						)
				  )
				: setMinutes(startTimePickUp ? (startTimePickUp as Date) : new Date(), 60),
		[datePickUp, fedexAvailability, startTimePickUp, isFedex]
	);

	const maxTimeEnd = useMemo(
		() =>
			isFedex && fedexAvailability && datePickUp
				? setHours(
						setMinutes(
							new Date(datePickUp),
							parseInt(
								fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.latest_times
									?.at(-1)
									?.slice(3, 5) ?? '0'
							)
						),
						parseInt(
							fedexAvailability[moment(datePickUp).format('YYYY-MM-DD')]?.latest_times
								?.at(-1)
								?.slice(0, 2) ?? '0'
						)
				  )
				: isPaquetexpress
				? setHours(setMinutes(new Date(), 50), 12)
				: setHours(setMinutes(new Date(), 0), 12),
		[datePickUp, fedexAvailability, isFedex, isPaquetexpress]
	);

	const minDate = useMemo(
		() =>
			!isFedex || !fedexAvailability
				? moment().toDate()
				: fedexAvailability[moment().format('YYYY-MM-DD')]
				? moment().toDate()
				: moment().add(1, 'days').toDate(),
		[isFedex, fedexAvailability]
	);

	// validate before move to summary
	useEffect(() => {
		setWantsToMove(false);
		if (!(wantsToMove && canContinueToSummaryPickUp)) {
			return;
		}
		if (!isRootOrAgent) {
			history.push('/resumen');
			return;
		}
		if (canPostSimulatedUser) {
			history.push('/resumen');
		}
	}, [
		wantsToMove,
		history,
		canContinueToSummaryPickUp,
		dispatch,
		isRootOrAgent,
		canPostSimulatedUser
	]);

	// Validate end date
	useEffect(() => {
		if (startTimePickUp) {
			dispatch(setTimePickUp(null, 'end'));
		}
	}, [startTimePickUp, dispatch]);

	const canContinueWhenPickingUp = () => {
		setWantsToMove(true);
		dispatch(triggerValidationsPickup(true));
		if (isRootOrAgent) {
			dispatch(triggerErrorSimulatedUser());
		}
	};

	useEffect(() => {
		if (selectionModelPickUp.length > 0) {
			const labelsList: ShipmentSendResponse[] = [];
			selectionModelPickUp.forEach((itemId) => {
				const dataItem = data?.find((guide) => guide.parcel_number === itemId);
				dataItem && labelsList.push(dataItem);
			});
			dispatch(setLabelsPickup(labelsList));
		} else {
			dispatch(resetLabelsPickup());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectionModelPickUp, data]);

	useEffect(() => {
		if (labelsPickup.length > 0 && addressPickUp) {
			const [first] = labelsPickup;
			if (addressPickUp.state !== first.origin.state) {
				dispatch(resetLabelsPickup());
			}
			const isFedex = labelsPickup[0]?.carrier === CARRIERS?.FEDEX;
			// Check availability for fedex
			if (isFedex) {
				checkFedexAvailability(addressPickUp?.zip_code);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addressPickUp, labelsPickup, checkFedexAvailability]);

	useEffect(() => {
		if (isTouchedPickup && !Boolean(datePickUp)) {
			infoRef.current?.scrollIntoView({ behavior: 'smooth' });
		}
	}, [isTouchedPickup, datePickUp]);

	// useEffect(() => {
	// 	dispatch(resetPickup());
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	return (
		<Fade in={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className={classes.root}>
				{/* Container */}
				<Grid
					ref={(instance) => (infoRef.current = instance)}
					container
					spacing={2}
					justifyContent='space-evenly'
					alignItems='center'
				>
					{/* Labels table */}
					<Grid item xs={12}>
						<TableShipmentSend isPickingUp setSelectionModelPickUp={setSelectionModelPickUp} />
					</Grid>
					<Grid item xs={12} container>
						{/* Origen */}
						<Grid item xs={12} md={6}>
							<AddressCard isPickingUp for='Origen' />
						</Grid>
						{/* Indicaciones */}
						<Grid item xs={12} md={6}>
							<InfoPickUpCard />
						</Grid>
					</Grid>

					{/* Fecha hora */}
					<Grid item xs={12}>
						<Grid container className={clsx([cardClasses.card, classes.card])} alignItems='center'>
							<Grid item xs={12} md={4}>
								<HeadingOne style={{ width: '100%', marginLeft: '6%' }} text='Fecha y hora' />
								<Typography
									variant='body2'
									color='primary'
									style={{ width: '100%', marginLeft: '6%', fontWeight: 500 }}
								>
									Las recolecciones se realizan en horario abierto
								</Typography>
							</Grid>
							<Grid item container alignItems='center' xs={12} md={8}>
								<Grid item container justifyContent='center'>
									<Grid item xs={12} sm={3}>
										<DatePicker
											disabled={isFedex && !fedexAvailability}
											selected={datePickUp as Date}
											onChange={(date) => dispatch(setDatePickUp(date as Date))}
											minDate={minDate}
											//maxDate={addDays(new Date(), 5)}
											customInput={
												<TextField
													className={classes.inputTimes}
													error={isTouchedPickup && !Boolean(datePickUp)}
													label='Fecha'
													variant='filled'
													disabled={isFedex && !fedexAvailability}
												/>
											}
										/>
									</Grid>
									<Grid item xs={12} sm={3}>
										<DatePicker
											disabled={isFedex && !fedexAvailability}
											selected={startTimePickUp as Date}
											onChange={(date) => dispatch(setTimePickUp(date as Date, 'start'))}
											minTime={minTimeStart}
											maxTime={maxTimeStart}
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={!isFedex ? 15 : 30}
											timeCaption='Hora'
											dateFormat='h:mm aa'
											customInput={
												<TextField
													className={classes.inputTimes}
													error={isTouchedPickup && !Boolean(startTimePickUp)}
													label='A partir'
													variant='filled'
													disabled={isFedex && !fedexAvailability}
												/>
											}
										/>
									</Grid>
									<Grid item xs={12} sm={3}>
										<DatePicker
											disabled={isFedex && !fedexAvailability}
											selected={endTimePickUp as Date}
											onChange={(date) => dispatch(setTimePickUp(date as Date, 'end'))}
											minTime={minTimeEnd}
											maxTime={maxTimeEnd}
											showTimeSelect
											showTimeSelectOnly
											timeIntervals={!isFedex ? 15 : 30}
											timeCaption='Hora'
											dateFormat='h:mm aa'
											customInput={
												<TextField
													className={classes.inputTimes}
													error={isTouchedPickup && !Boolean(endTimePickUp)}
													label='Cierre'
													variant='filled'
													disabled={isFedex && !fedexAvailability}
												/>
											}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/* Bottom Bar */}
				<ControlBar
					onContinue={canContinueWhenPickingUp}
					close
					onLeftControl={() => {
						dispatch(resetPickup());
					}}
					rightText='Continuar'
				>
					<RateOrSendControl />
				</ControlBar>
			</div>
		</Fade>
	);
};

export default PickUp;
