import React, { useState, useContext } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useSelector } from '~store/store';

import InputSearchAddress from '../Inputs/InputSearchAddress';
import AutoCompleteMaps from '../Maps/AutoCompleteMaps';
import { HeadingOne } from '~components/Headings';

import { useCardStyles } from '../../hooks/useStyles';

import {
	setAddressRate,
	toggleDestinationAddressComponent,
	toggleOriginAddressComponent
} from '../../store/actions';
import DrawContext from '../../context/DrawContext';
import usePermissions from '~hooks/usePermissions';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		height: 250,
		'&>*': {
			marginTop: theme.spacing(1)
		}
	},
	container: {
		color: theme.palette.text.disabled
	},
	caption: {
		marginLeft: '12%'
	}
}));

interface Props {
	for: 'Origen' | 'Destino';
}

/**
 * This is the component when ratting
 */
const AddressCardRate: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const dispatch = useDispatch();

	const { originAddressComponent, destinationAddressComponent } = useSelector(
		(state) => state.rate
	);
	const { currentFlow } = useSelector((state) => state.flow);

	const { setNotification } = useContext(DrawContext);
	const { can } = usePermissions();
	const canHaveActions = can('canRead.addresses');

	const [searchWithMaps, setSearchWithMaps] = useState(
		currentFlow === 'rate' || !canHaveActions
			? true
			: props.for === 'Destino'
			? destinationAddressComponent === 'maps'
			: originAddressComponent === 'maps'
	);

	return (
		<div
			className={clsx([classes.root, cardClasses.softColor])}
			style={{ borderRadius: props.for === 'Destino' ? '0 20px 20px 0' : '20px 0 0 20px' }}
		>
			<HeadingOne style={{ textAlign: 'center' }} text={props.for} />

			<FormControlLabel
				control={
					<Switch
						disabled={!canHaveActions}
						checked={searchWithMaps}
						onChange={() => {
							setSearchWithMaps((prev) => !prev);
							if (props.for === 'Destino') {
								dispatch(toggleDestinationAddressComponent());
							} else {
								dispatch(toggleOriginAddressComponent());
							}
						}}
						color='primary'
					/>
				}
				label={searchWithMaps ? 'Buscando con Google Maps' : 'Buscar con Google Maps'}
			/>
			<div className={classes.container}>
				{searchWithMaps && (
					<React.Fragment>
						<AutoCompleteMaps
							isRating
							searchCallback={(p) => {
								if (!p.postal_code) {
									setNotification({
										message:
											'Lo sentimos pero Google Maps no regreso un código postal para esta dirección, favor de dar de alta la dirección en tu libreta.',
										severity: 'error'
									});
									return;
								}
								dispatch(
									setAddressRate(
										{
											id: new Date().toISOString(),
											country: p.country as string,
											coordinates: `${p.coordinates.lat}, ${p.coordinates.lng}`,
											number: p.street_number as string,
											street: p.route as string,
											business_name: p.name as string,
											reference: '',
											reference_streets: '',
											suite_number: '',
											type: '',
											city: p.locality ?? '',
											contact: null,
											neighborhood: p.sublocality_level_1 as string,
											state: p.administrative_area_level_1 as string,
											zip_code: p.postal_code,
											alias: '',
											tax_id: ''
										},
										props.for === 'Destino' ? 'destination' : 'origin'
									)
								);
							}}
						/>
						{canHaveActions && 'Apagar el switch para buscar con libreta'}
					</React.Fragment>
				)}
				{!searchWithMaps && canHaveActions && (
					<form autoComplete='off'>
						<InputSearchAddress
							isRating
							for={props.for === 'Destino' ? 'addressDestination' : 'addressOrigin'}
							label={'Dirección ' + props.for}
						/>
						<span className={classes.caption}>Actualmente buscando en libreta</span>
					</form>
				)}
			</div>
		</div>
	);
};

export default AddressCardRate;
